import { ReactComponent as Close } from "../../img/Close.svg";

export interface IBaseRegistrationModalProps {
  children?: React.ReactNode;
  closeModalFn: () => void;
  headerText: string;
  Image: React.ReactNode;
}

const BaseRegistrationModal: React.FC<IBaseRegistrationModalProps> = ({
  children,
  closeModalFn,
  headerText,
  Image,
}) => {
  return (
    <div className="popup-background">
      <div className="modal-create-container">
        <div className="modal-header">
          <div className="modal-title-container">
            {Image}
            <div className="unit-title">{headerText}</div>
          </div>
          <Close
            onClick={closeModalFn}
            className="modal-header-img"
            title="Close modal"
          />
        </div>

        <div className="modal-create-body">{children}</div>
      </div>
    </div>
  );
};

export default BaseRegistrationModal;
