import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  isRemoteLockPolling,
  isRemoteLockTokenInvalidVar,
} from "../../apollo/LocalState";
import { ReactComponent as ALert } from "../../../img/Alert.svg";
import BaseModal from "../../modals/BaseModal";
import { openExternalPopup } from "../../../utils/integrationUtils";

export interface InvalidTokenErrorModalProps {
  startPolling: (pollInterval: number) => void;
}

const InvalidTokenErrorModal: React.FC<InvalidTokenErrorModalProps> = ({
  startPolling,
}) => {
  const [externalPopup, setExternalPopup] = useState<any>(null);
  const { organizationId } = useParams();

  useEffect(() => {
    if (!externalPopup) {
      return;
    }
    const timer = setInterval(() => {
      if (!externalPopup) {
        clearInterval(timer);
        return;
      }
      // When external popup is closed we close LoginModal component and start polling
      // on GetIntegrationsForOrganization every second by setting isRemoteLockPolling to true.
      if (externalPopup.closed) {
        isRemoteLockTokenInvalidVar(false);
        isRemoteLockPolling(true);
        setExternalPopup(null);
        clearInterval(timer);
        startPolling(1000);
      }
    }, 500);
  }, [externalPopup]);

  const actionButtonFn = () => {
    isRemoteLockTokenInvalidVar(false);
    openExternalPopup(organizationId, setExternalPopup);
  };
  return (
    <BaseModal
      actionButtonFn={actionButtonFn}
      actionButtonText="Log in Again"
      closeModalFn={() => {
        isRemoteLockTokenInvalidVar(false);
      }}
      headerText="Something Went Wrong"
    >
      <ALert title="alert" />
      <div className="modal-body-text-container">
        <div className="menu menu-light">
          There was a problem communicating with your RemoteLock account.
        </div>
        <br />
        <div className="menu menu-light">
          You must log in again to continue using this integration.
        </div>
        <br />
        <div className="menu menu-light">
          If this problem continues, please contact Brilliant.
        </div>
      </div>
    </BaseModal>
  );
};

export default InvalidTokenErrorModal;
