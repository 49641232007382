import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { IConfiguration } from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { GET_CONFIGURATIONS } from "../../api/gqlQueries";
import {
  organizationIdNameVar,
  smallHeaderBackArrow,
  smallHeaderTitle,
} from "../apollo/LocalState";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

import ConfigurationsCard from "./ConfigurationsCard";

const Configurations: React.FC = () => {
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  const { loading, data } = useQuery<IConfiguration>(GET_CONFIGURATIONS);
  useEffect(() => {
    smallHeaderTitle("Configurations");
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, []);
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }

  const crumbs = [
    {
      name: organizationIdName.organizationName,
      path: `/${organizationIdName.organizationId}/organization`,
    },
    { name: "Configurations", path: "" },
  ];

  return (
    <div className="configuration-container">
      <div className="configuration-container-inner">
        <div className="property-details-breadcrumbs">
          <Breadcrumbs
            crumbs={crumbs}
            rootCrumb={{ name: "Organizations", path: "/" }}
          />
        </div>
        <div
          data-testid="configurations-header"
          className="configuration-title menu"
        >
          Configurations
        </div>
        <div className="configuration-list">
          {data.configurations.map((configuration) => {
            return (
              <ConfigurationsCard
                key={configuration.id}
                configuration={configuration}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Configurations;
