import { Link, useParams } from "react-router-dom";

import { ReactComponent as Arrow } from "../../img/Arrow.svg";
import { IPropertyData } from "../../interfaces/interfaces";

const BuildingCard: React.FC<{ property: IPropertyData }> = ({ property }) => {
  const params = useParams();
  const { organizationId } = params;
  return (
    <Link
      to={`/${organizationId}/buildings/${property.id}`}
      className="property-container"
    >
      <div key={property.id} className="property-container-layout">
        <div>
          <div className="unit-title hidden-name">{property.propertyName}</div>
          <div className="unit-description">
            {`${property.addressLine1}, ${property.city}, ${property.state} ${property.zipcode}`}
          </div>
        </div>
        <Arrow className="brilliant-card-installation-icon" />
      </div>
    </Link>
  );
};

export default BuildingCard;
