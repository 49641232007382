import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import InvalidTokenErrorModal from "../integrations/modals/InvalidTokenErrorModal";
import RealPageLogInModal from "../login/RealPageLoginModal";
import RemoteLockLogInModal from "../login/RemoteLockLogInModal";
import RemoteLockLoginSuccessModal from "../integrations/modals/RemoteLockLoginSuccessModal";
import RemoteLockMobileModal from "../integrations/modals/RemoteLockMobileModal";
import Spinner from "../Spinner";
import { checkIntegrations } from "../../utils/integrationUtils";
import { oneMinInMs } from "../../utils/utils";
import { GET_INTEGRATION_TYPES_FOR_ORGANIZATION } from "../../api/gqlQueries";
import EntrataLogInModal from "../login/EntrataLogInModal";
import {
  IOrganizationData,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import {
  GET_REMOTE_LOCK_POLLING_STATE,
  isRemoteLockPolling,
  isRemoteLockTokenInvalidVar,
  organizationIdNameVar,
  smallHeaderBackArrow,
  smallHeaderTitle,
} from "../apollo/LocalState";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

import IntegrationCard from "./IntegrationCard";

const Integrations: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  let { organizationId } = params;
  if (organizationId === undefined) {
    organizationId = "";
  }
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  const isRemoteLockTokenInvalid = useReactiveVar(isRemoteLockTokenInvalidVar);

  // Tracks whether the user is logging into RemoteLock.
  const [attemptingLogin, setAttemptingLogin] = useState(false);
  // Controls display of the login success modal.
  const [showRemoteLockLoginSuccessModal, setShowRemoteLockLoginSuccessModal] =
    useState(false);

  const [showRemoteLockLogInModal, setShowRemoteLockLogInModal] =
    useState(false);
  const [showEntrataLogInModal, setShowEntrataLogInModal] = useState(false);
  const [showRealPageLogInModal, setShowRealPagLogInModal] = useState(false);
  const [showRemoteLockMobileModal, setShowRemoteLockMobileModal] =
    useState(false);
  const { loading, data, startPolling, stopPolling } = useQuery<
    IOrganizationData,
    IOrganizationParams
  >(GET_INTEGRATION_TYPES_FOR_ORGANIZATION, {
    variables: { organizationId },
  });
  const { data: startedRemoteLockPolling } = useQuery<{
    isRemoteLockPolling: boolean;
  }>(GET_REMOTE_LOCK_POLLING_STATE, {
    variables: { organizationId },
  });

  const closeLoginSuccessModalFn = () => {
    setShowRemoteLockLoginSuccessModal(false);
    setAttemptingLogin(false);
  };
  function stopPollingIntegrations() {
    isRemoteLockPolling(false);
    stopPolling();
  }

  let hasRemoteLockIntegration = false;
  let hasEntrataIntegration = false;
  let realPageIntegration = false;
  useEffect(() => {
    smallHeaderTitle("Integrations");
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, []);
  useEffect(() => {
    if (hasRemoteLockIntegration) {
      setShowRemoteLockLoginSuccessModal(true);
      stopPollingIntegrations();
    }
    if (startedRemoteLockPolling?.isRemoteLockPolling) {
      setTimeout(stopPollingIntegrations, oneMinInMs);
    }
  }, [data, startedRemoteLockPolling?.isRemoteLockPolling]);

  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  const crumbs = [
    {
      name: organizationIdName.organizationName,
      path: `/${organizationIdName.organizationId}/organization`,
    },
    { name: "Integrations", path: "" },
  ];

  ({
    entrataIntegration: hasEntrataIntegration,
    realPageIntegration,
    remoteLockIntegration: hasRemoteLockIntegration,
  } = checkIntegrations(data.organization.integrations));

  return (
    <div className="configuration-container">
      {isRemoteLockTokenInvalid && (
        <InvalidTokenErrorModal startPolling={startPolling} />
      )}
      {showRemoteLockLogInModal && (
        <RemoteLockLogInModal
          closeLogInModal={() => setShowRemoteLockLogInModal(false)}
          setAttemptingLoginFn={setAttemptingLogin}
          startPolling={startPolling}
        />
      )}
      {showEntrataLogInModal && (
        <EntrataLogInModal
          closeLogInModal={() => setShowEntrataLogInModal(false)}
        />
      )}
      {showRealPageLogInModal && (
        <RealPageLogInModal
          closeLogInModal={() => setShowRealPagLogInModal(false)}
        />
      )}
      {attemptingLogin && showRemoteLockLoginSuccessModal && (
        <RemoteLockLoginSuccessModal
          actionButtonFn={() => navigate("/remoteLock")}
          closeModalFn={closeLoginSuccessModalFn}
        />
      )}
      {showRemoteLockMobileModal && (
        <RemoteLockMobileModal
          closeMobileModal={() => setShowRemoteLockMobileModal(false)}
        />
      )}
      <div className="configuration-container-inner">
        <div className="property-details-breadcrumbs">
          <Breadcrumbs
            crumbs={crumbs}
            rootCrumb={{ name: "Organizations", path: "/" }}
          />
        </div>
        <div>
          <div className="configuration-title menu">Integrations</div>
          <div className="configuration-list">
            <IntegrationCard
              hasIntegration={hasEntrataIntegration}
              linkTo={`/${organizationIdName.organizationId}/entrata`}
              title="Entrata"
              onSettingsClick={() => setShowEntrataLogInModal(true)}
            />
            <IntegrationCard
              hasIntegration={realPageIntegration}
              linkTo={`/${organizationIdName.organizationId}/realPage`}
              title="RealPage"
              onSettingsClick={() => setShowRealPagLogInModal(true)}
            />
            <IntegrationCard
              hasIntegration={hasRemoteLockIntegration}
              linkTo={`/${organizationIdName.organizationId}/remoteLock`}
              title="RemoteLock"
              onSettingsClick={() => setShowRemoteLockLogInModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
