import { Dictionary } from "lodash";

export enum EWarningTypes {
  InstallIncomplete = "INSTALL_INCOMPLETE",
  OfflineDevices = "OFFLINE_DEVICES",
}

export enum DeviceStatus {
  Online = "ONLINE",
  Offline = "OFFLINE",
  Disconnected = "DISCONNECTED",
}

export enum DeviceTypes {
  BUILDING_ENTRY_PANEL = "BUILDING_ENTRY_PANEL",
  CLIMATE_SENSOR = "CLIMATE_SENSOR",
  DOORBELL = "DOORBELL",
  LIGHT = "LIGHT",
  LOCK = "LOCK",
  MUSIC = "MUSIC",
  OFFLINE_LOCK = "OFFLINE_LOCK",
  OUTLET = "OUTLET",
  THERMOSTAT = "THERMOSTAT",
  UNKNOWN = "UNKNOWN",
  WATER_SHUTOFF_VALVE = "WATER_SHUTOFF_VALVE",
}

export enum ERole {
  MDU_BRILLIANT_ADMIN = "mdu_brilliant_admin",
  MDU_OPERATOR = "mdu_operator",
  SUPERUSER = "superuser",
}

export enum EPermissionActions {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export type Role =
  | ERole.MDU_BRILLIANT_ADMIN
  | ERole.MDU_OPERATOR
  | ERole.SUPERUSER;

export interface IAppState {
  loading: boolean;
  newUser: IRegistrationData;
  selectedScheduledConfiguration: ISelectedScheduledConfiguration;
  schedulerDatetime: ISchedulerDatetime;
  user: ILoginData;
  entrataData: IEntrataData;
  unitDetails: IPropertyUnitData;
  propertyData: IPropertyDetails;
  userManagement: IUserManagementData;
  alertManagement: IAlertManagementData;
  newUserRegistration: IUserRegistrationData;
}

export interface IOrganization {
  numHomes: number;
  buildings: IPropertyData[];
  numBuildings: number;
  name: string;
  id: string;
}

export interface IUser {
  familyName: string;
  givenName: string;
  birthdate: string;
  role: Role;
  organizations: IOrganization[];
}

export interface IUserData {
  user: IUser;
}

export interface IUserParams {
  userId: string;
}

export interface IAlertNotificationsParams {
  userId: string;
  propertyId: string;
}

export interface IUserManagementData {
  invitedUserError: EUserManagementError;
}

export interface IAlertManagementData {
  isSideBarVisible: boolean;
}

export interface IOrganizationParams {
  organizationId: string;
}

export enum EUserManagementError {
  noError = 0, // The API request was successful.
  invitedUserAlreadyAssociatedWithOrganization, // The user already has a role with an organization and cannot have another role.
}

export interface ISelectedScheduledConfiguration {
  id: string;
  title: string;
  date: string;
  time: string;
  createdDate: string;
  showModal: boolean;
}

export interface ISchedulerDatetime {
  date: string;
  time: string;
}

export interface IConfiguration {
  configurations: IConfigurationData[];
}

export interface IConfigurationDetails {
  configuration: IConfigurationData;
}

export interface IConfigurationData {
  id: string;
  title: string;
  description: string;
}

export interface INetworkTypeData {
  id: string;
  title: string;
  description: string;
}

export interface IScheduledConfigurationsData {
  configurationTitle: string;
  scheduledDatetime: string;
  id: string;
  createdDatetime: string;
}

export interface IWifiCredentials {
  networkName: string;
  networkType: string;
  password: string;
  securityType: string;
}

export interface IUnitDetailsData {
  home: IUnitDetailsDataHome;
}

export interface IInstallationDetails {
  installedBrilliantControls: IBrilliantControlsCard[];
  installedBrilliantPlugs: IBrilliantPlugCard[];
  installedBrilliantSwitches: IBrilliantSwitchCard[];
  installedIntegrationDevices: IInstalledIntegrationDevice[];
}

export interface IBrilliantDevices {
  installedBrilliantControls: IBrilliantControlsCard[];
  installedBrilliantPlugs: IBrilliantPlugCard[];
  installedBrilliantSwitches: IBrilliantSwitchCard[];
}

export interface IUnitDetailsDataHome {
  __typename?: string;
  id: string;
  homeId: string;
  propertyName: string;
  floor: number;
  brilliantControls: IUnitDetailsControls[];
  brilliantSwitches: IBrilliantSwitchCard[];
  brilliantPlugs: IBrilliantPlugCard[];
  alerts: IAlert[];
  installationDetails: IInstallationDetails;
  integrations: IUnitDetailsIntegrations[];
  isInstallIncomplete: boolean;
  numResidents: number;
  isHubless: boolean;
  numInvitedResidents: number;
  parentProperties: IUnitDetailsParentProperties[];
  numRemotelockDevices: number;
  reconfigurationState: string;
  scheduledConfigurations: IScheduledConfigurationsData[];
  wifiCredentials: IWifiCredentials;
  numBrilliantControls: number;
  numBrilliantPlugs: number;
  numBrilliantSwitches: number;
  numIntegrations: number;
  numOfflineDevices: number;
  warnings: IWarning[];
}

export interface IWarning {
  warningType: EWarningTypes;
}

export interface IShowResidentsData {
  home: IShowResidents;
}

export interface IShowResidents {
  residents: IRegisteredUser[];
  invitedResidents: IInvitedUser[];
}

export interface IShowPortalUsersData {
  organization: IShowPortalUsers;
}

export interface IShowPortalUsers {
  name: string;
  portalUsers: IRegisteredUser[];
  invitedPortalUsers: IInvitedUser[];
}

export interface IInvitedUser {
  id: string;
  remotelockCardNumber: string | null;
  emailAddress: string;
  expirationDatetime: string;
}

export interface IRegisteredUser {
  id: string;
  remotelockCardNumber: string | null;
  familyName: string | null;
  givenName: string | null;
  emailAddress: string | null;
  joinedPropertyDatetime: string | null;
  deletedDatetime?: string | null;
}

export interface IUnitDetailsControls {
  id: string;
  name: string;
  numGangs: number;
  isReconfiguring: boolean;
  connectivityStatus: DeviceStatus;
  lastKnownCloudSync: string;
}

export interface IIntegrationDevices {
  name: string;
  connectivityStatus: string;
  deviceType: DeviceTypes;
  isCommonArea?: boolean;
}

export interface IUnitDetailsIntegrations {
  name: string;
  integrationDevices: IIntegrationDevices[];
  numDevices: number;
}

export interface IBackupWifiNetworkModal {
  propertyId: string;
  networkName: string;
  password: string;
  networkType: string;
  securityType: string;
}

export interface IInstalledIntegrationDevice {
  id: string;
  name: string;
  integrationType: string;
}

export interface IBrilliantSwitchCard {
  name: string;
  id: string;
  isReconfiguring: boolean;
  connectivityStatus: DeviceStatus;
}

export interface IBrilliantPlugCard {
  name: string;
  id: string;
  isReconfiguring: boolean;
  connectivityStatus: DeviceStatus;
}

export interface IBrilliantControlsCard {
  name: string;
  numGangs: number;
  id: string;
  connectivityStatus: DeviceStatus;
  isReconfiguring: boolean;
  lastKnownCloudSync: string;
}

export interface IIntegrationsCard {
  name: string;
  integrationDevices: IIntegrationDevices[];
  numDevices: number;
}

export interface IInstallationSettingsCard {
  settingName: string;
}

export interface IBackupWifiSettingsCard {
  networkName?: string;
}

export interface IUnitDetailsParentProperties {
  propertyId: string;
  name: string;
}

export interface IPropertyDetails {
  property: IPropertyData;
  homes: IPropertyUnitData[];
  floors: Dictionary<IPropertyUnitData[]>;
  propertyError: EPropertyDetailsError;
  buildingHomesError: EPropertyDetailsError;
}

export interface IBreadcrumbs {
  name: string;
  path: string;
}

export enum EPropertyFilter {
  notFiltered = 0,
  withAlerts,
  withWarnings,
  occupied,
  vacant,
}

export enum EUnitDetailsFilter {
  brilliants = 0,
  integrations,
  residents,
}

export enum EShowTenantSteps {
  ShowTenantAccessCredentialsStep = 0,
  ShowTenantGeneralStep,
}

export interface IObjectToIUnitDetailsDataHomeAmount {
  [key: string]: number;
}

export interface IObjectToIFiltersData {
  [key: string]: IFiltersData;
}

export enum EFilterColorMethod {
  STROKE = 0,
  FILL,
}

export interface IFiltersData {
  cssColorMethod: EFilterColorMethod;
  name: string;
  title: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  filterTypeEnum: EPropertyFilter;
}

export interface IMeta {
  touched: boolean;
  error: string;
  valid: boolean;
}

// Disable camelcase here since these are the raw values we expect from the API.
/* eslint-disable camelcase */
export interface IActionPropertyUnit {
  floor: number;
  property_id: string;
  name: string;
  num_controls: number;
  num_thirdparty_integrations: number;
  num_tenants: number;
}
/* eslint-enable camelcase */

export interface IPropertyUnitData {
  alerts: string[];
  floor?: number;
  isHubless: boolean;
  propertyId: string;
  name: string;
  numBrilliants: number;
  numThirdpartyIntegrations: number;
  numTenants: number;
  numInvitedResidents: number;
  reconfigurationState: string;
  warnings: string[];
}

export enum ELoginError {
  noError = 0, // The login API request did not experience an error.
  isWrong, // A wrong userName or password was used when calling the login API.
  isWrongCode, // The user provided the wrong code for the multifactor step.
  isInvalidToken, // The email encoded in the token used for MFA verification does not match the user.
  isLimit, // The login API was rate limited.
  isError, // An undefined error was encountered with the login API.
  isForbidden, // The account is not set up for management access.
}

export interface IObjectIndexType {
  [key: string]: string;
}

export interface ILoginData {
  isAuthenticated: boolean; // Whether the user is fully logged in.
  isSecondStep: boolean; // Indicates that the user is in the two factor portion of the auth flow.
  email: string; // The logged in user's email address.
  error: ELoginError;
  mfaToken: string; // Token used for MFA code verification.
  phoneNumber: string; // The logged in user's phone number.
  organizationId: string; // The ID of the organization to which the user belongs.
  userId: string; // The user's ID.
}

/**
 * Registration
 */
export interface IRegistrationData {
  emailJWT: string; // The JWT used to validate sending validation email.
  accountCompleted: boolean; // Whether the user has completed their account through the registration form.
  termsAccepted: boolean; // Whether the user has accepted terms and conditions.
  emailVerified: boolean; // Whether the user has verified their email.
  phoneVerified: boolean; // Whether the user has verified their mobile device.
  birthdate: string; // The user's birthdate. Used to pre-populate form for an existing user.
  email: string; // The email address used to register.
  error: ERegistrationError;
  familyName: string; // The user's last name. Used to pre-populate form for an existing user.
  givenName: string; // The user's first name. Used to pre-populate form for an existing user.
  jwt: string; // The JWT used to validate the next Web API call for going to the next step.
  sendVerificationTextJWT: string; // The JWT used to validate the Web API call for sending a verification text.
  organizationId: string; // The ID of the organization that the user is joining.
  organizationName: string; // The name of the organization that the user is joining.
  phoneNumber: string; // The logged in user's phone number.
  userId: string; // The ID of the user.
}

export interface IUserRegistrationData {
  emailJWT: string; // The JWT used to validate sending validation email.
  accountCompleted: boolean; // Whether the user has completed their account through the registration form.
  emailVerified: boolean; // Whether the user has verified their email.
  birthdate: string; // The user's birthdate. Used to pre-populate form for an existing user.
  email: string; // The email address used to register.
  error: ERegistrationError;
  familyName: string; // The user's last name. Used to pre-populate form for an existing user.
  givenName: string; // The user's first name. Used to pre-populate form for an existing user.
  jwt: string; // The JWT used to validate the next Web API call for going to the next step.
  userId: string; // The ID of the user.
  propertyId: string; // The ID of the property.
  propertyName: string; // The name of the property.
  buildingName: string; // The name of the building.
  configHomeName: string; // The name of the home in the configuration device.
}

export interface IRegistrationQueryParams {
  email: string;
  jwt: string;
  propertyId: string;
}

export interface IRegistrationPhoneNumber {
  phoneNumber: string;
}

export interface IRegistrationAccountData {
  birthdate: string;
  familyName: string;
  givenName: string;
  jwt: string;
  organizationId: string;
  organizationName: string;
  sendVerificationTextJWT: string;
  userId: string;
}

export interface IRegistrationJWT {
  jwt: string;
}

export interface IRegistrationStep {
  form: React.ReactNode;
  leftTitle: string;
  leftDescription: JSX.Element | string;
  rightMessage: JSX.Element | string;
  error?: ELoginError;
  isUser?: boolean;
  messageAfterForm?: JSX.Element;
  reconfigurationError?: string;
}

export interface IActionRegistration {
  type: string;
  payload?:
    | IRegistrationQueryParams
    | IRegistrationAccountData
    | IRegistrationPhoneNumber
    | IRegistrationJWT
    | IUserRegistrationData;
}

export interface IRegistrationAccountFormData {
  emailAddress: string;
  birthdate: string;
  familyName: string;
  givenName: string;
  organizationName: string;
  newPassword: string;
  confirmPassword: string;
  phoneNumber: string;
  userId: string;
}

export interface IRegistrationTenantAccountFormData {
  emailAddress: string;
  birthdate: string;
  familyName: string;
  givenName: string;
  newPassword: string;
  confirmPassword: string;
  jwt: string;
  userId: string;
}

export interface IVerificationFormData {
  verificationCode: string;
}

export interface IVerificationFormCustomProps {
  registrationError: ERegistrationError;
  submitText: string;
  resendCodeAction?: () => void;
}

export enum ERegistrationError {
  noError = 0, // The API request was successful.
  birthdateFormatInvalid, // The birthdate is invalid and could not be parsed as MM/DD/YYYY.
  birthdateInvalid, // The user is under 13 years old and thus not allowed to create an account.
  expiredInvitation, // The error requires the user get a new invitation for registration.
  familyNameInvalid, // The user's family name is invalid (e.g. is all whitespace).
  givenNameInvalid, // The user's given name is invalid (e.g. is all whitespace).
  invalidInvitation, // The invitation is no longer authorized.
  isInvalidToken, // The provided token was invalid for the API request.
  isLimit, // The API request was rate-limited.
  isWrongCode, // The user provided the wrong code for verification.
  isWrongTenantCode, // The new tenant provided the wrong code for verification.
  passwordInvalid, // The password is less than 8 characters in length.
  passwordMismatch, // The password and confirm password fields do not match.
  userAlreadyAssociatedWithOrganization, // The user already has a role with an organization and cannot have another role.
  verificationRateLimited, // The verification request was rate-limited.
}

export enum EReconfigurationError {
  noError = 0, // The API request was successful.
  reconfigurationInProgress, // Reconfiguration is in progress.
  reconfigurationFailed, // Reconfiguration failed.
}

export enum EUserManagementGenericError {
  noError = 0, // The API request was successful.
  invalidDomain,
  somethingWentWrong, // Unhandled error happened.
}

export interface IActionProperties {
  type: string;
  payload:
    | IActionPropertyData
    | IActionPropertyUnit[]
    | string
    | Record<string, unknown>;
}

export interface IActionTypeOnly {
  type: string;
}

export interface IActionTypeWithStringPayload {
  type: string;
  payload: string;
}

export interface IActionTypeWithBooleanPayload {
  type: string;
  payload: boolean;
}

export interface IActionLogin {
  type: string;
  payload: {
    email: string;
    mfaToken: string;
    organizationId: string;
    phoneNumber: string;
    userId: string;
  };
}

export interface IScheduleForm {
  what: string;
  when: string;
}

export interface ILoginForm {
  emailField: string;
  passwordField: string;
}

export enum EEntrataError {
  noError = 0, // The Entrata linking API did not experience an error.
  notAuthorized = 1, // The user is not authorized to link the integration.
  upstreamError = 2, // There was an upstream error communicating with Entrata.
  unknownError = 3, // There was an unknown error from the API.
}

export interface IEntrataData {
  error: EEntrataError;
  isLoading: boolean;
}

export enum EPropertyDetailsError {
  noError = 0, // There was no error fetching the property details data.
  unknownError = 1, // There was an unknown error from the API.
}

export interface IPropertyData {
  id: string;
  propertyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
}

// Disable camelcase here since these are the raw values we expect from the API.
/* eslint-disable camelcase */
export interface IActionPropertyData {
  property_id: string;
  name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zipcode: string;
}

export interface IActionDashboardSummaryBuilding {
  buildings: IActionPropertyData[];
  buildings_cursor?: string;
}
/* eslint-enable camelcase */

export interface IActionDashboardSummary {
  type: string;
  // On success, we will get IActionDashboardSummaryBuilding, an Object
  // (if the API returned an error JSON), or a string with an error message.
  payload?: IActionDashboardSummaryBuilding | Record<string, unknown> | string;
}

export interface IUnitAction {
  type: string;
  payload: IPropertyUnitData;
}

export interface IPropertyIdVariable {
  propertyId: string;
}

export interface IOrganizationIdVariable {
  organizationId: string;
}

export interface IBuildingIdVariable {
  buildingId: string;
}

export interface IAlertData {
  organization: {
    alerts: IAlert[];
  };
}

export interface IAlert {
  __typename?: string;
  id: string;
  alarmType: string;
  deviceName: string;
  building: IAlertBuildingData;
  home: IUnitDetailsDataHome;
  ambientTemperatureF?: number;
  deviceType?: string;
}

export interface IAlertBuildingData {
  __typename?: string;
  id: string;
  propertyName: string;
}

export interface IRemoteLockIntegrationDevice {
  id: string;
  name: string;
  accessibleType: string;
  remotelockAccessGrantId?: string;
  numDevices?: number;
}

export interface IAccessDevice {
  id: string;
  device: IRemoteLockIntegrationDevice;
}

export interface IDoorGroup {
  id: string;
  doorGroup: IRemoteLockIntegrationDevice;
}

export interface IEntrataIntegration {
  integrationType: string;
}

export interface IRemoteLockHome {
  id: string;
  propertyName: string;
  remotelockUnitAccessDevices: IAccessDevice[];
  remotelockDoorGroups: IDoorGroup[];
}

export interface IRemoteLockBuilding {
  id: string;
  homes: IRemoteLockHome[];
}

export interface IRemoteLockIntegration {
  integrationType: string;
  devices: IRemoteLockIntegrationDevice[];
  doorGroups: IRemoteLockIntegrationDevice[];
}

export interface IOrganizationData {
  organization: {
    integrations: IRemoteLockIntegration[];
  };
}

export interface IRemotelockDeviceAssignment {
  deviceType: string;
  propertyId: string;
  remotelockDeviceName: string;
  remotelockAccessibleType: string;
  remotelockDeviceId: string;
}

export interface IRemotelockDeviceRemoval {
  propertyId: string;
  remotelockAccessGrantId: string;
  remotelockDeviceId: string;
}

export interface IRemoteLockChangedHome {
  id: string;
  homeName: string;
  deviceType: string;
  remotelockAccessDevices: IRemoteLockIntegrationDevice[];
}

// Local apollo variables

export interface INotificationSetting {
  notificationMethod: string;
  enabled: boolean;
}

export interface IAlarmNotificationSetting {
  alarmType: string;
  notificationSettings: INotificationSetting[];
}

export interface IUserDataWithAccountInfo {
  user: {
    familyName: string;
    givenName: string;
    birthdate: string;
    mfaPhoneNumber: string;
    alarmNotificationSettings: IAlarmNotificationSetting[];
  };
}

export interface IUpdateUserNotificationSettingRequest {
  userId: string;
  alarmType: string;
  notificationMethod: string;
  enabled: boolean;
}

export interface IUpdateUserPropertyNotificationSettingRequest {
  userId: string;
  alarmType: string;
  notificationMethod: string;
  enabled: boolean;
  propertyId: string;
}

export interface IUpdateUserNotificationSettingResponse {
  updateUserNotificationSetting: {
    alarmNotificationSettings: IAlarmNotificationSetting[];
  };
}

export interface IWhatNextList {
  bulletPoint: JSX.Element;
  subBulletPoints?: string[];
}

export interface IIntegrations {
  entrataIntegration: boolean;
  realPageIntegration: boolean;
  remoteLockIntegration: boolean;
}

export interface IIntegrationsNameAndLink {
  name: string;
  landingURL: string;
}

export interface ISmartCardCredentialsData {
  id: string;
  badgeNumber: string;
}
