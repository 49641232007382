import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import _ from "lodash";

import {
  EFilterColorMethod,
  EPropertyFilter,
  IBuildingIdVariable,
  IObjectToIFiltersData,
  IObjectToIUnitDetailsDataHomeAmount,
  IUnitDetailsDataHome,
} from "../../interfaces/interfaces";
import {
  organizationIdNameVar,
  smallHeaderBackArrow,
  smallHeaderDescription,
  smallHeaderTitle,
} from "../apollo/LocalState";
import { ReactComponent as AlertTriangle } from "../../img/AlertTriangle.svg";
import { ReactComponent as Bell } from "../../img/propertyDetails/Bell.svg";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { ReactComponent as Building } from "../../img/propertyDetails/Building.svg";
import { GET_BUILDING } from "../../api/gqlQueries";
import { ReactComponent as Key } from "../../img/propertyDetails/Key.svg";
import { ReactComponent as LargeBuilding } from "../../img/propertyDetails/LargeBuilding.svg";
import { ReactComponent as Properties } from "../../img/Properties.svg";
import { ReactComponent as Search } from "../../img/propertyDetails/Search.svg";
import Spinner from "../Spinner";
import { ReactComponent as Users } from "../../img/propertyDetails/users.svg";

import Floor from "./Floor";
import FilterListSelect from "./FilterListSelect";
import FilterList from "./FilterList";

export interface IBuildingDetailsData {
  building: IBuilding;
}

export interface IBuilding {
  id: string;
  propertyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
  homes: IUnitDetailsDataHome[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filteredMethods: any = {
  [EPropertyFilter.notFiltered]: {},
  [EPropertyFilter.withAlerts]: (sortedHome: IUnitDetailsDataHome) => {
    return sortedHome.alerts.length !== 0;
  },
  [EPropertyFilter.withWarnings]: (sortedHome: IUnitDetailsDataHome) => {
    return sortedHome.warnings.length !== 0;
  },
  [EPropertyFilter.occupied]: (data: IUnitDetailsDataHome) => {
    return data.numResidents > 0;
  },
  [EPropertyFilter.vacant]: (data: IUnitDetailsDataHome) => {
    return data.numResidents === 0;
  },
};

export const FilterItemsData: IObjectToIFiltersData = {
  [EPropertyFilter.notFiltered]: {
    cssColorMethod: EFilterColorMethod.FILL,
    filterTypeEnum: EPropertyFilter.notFiltered,
    icon: Building,
    name: "All Units",
    title: "Properties",
  },
  [EPropertyFilter.withAlerts]: {
    cssColorMethod: EFilterColorMethod.FILL,
    filterTypeEnum: EPropertyFilter.withAlerts,
    icon: Bell,
    name: "Units with Alerts",
    title: "Bell",
  },
  [EPropertyFilter.withWarnings]: {
    cssColorMethod: EFilterColorMethod.STROKE,
    filterTypeEnum: EPropertyFilter.withWarnings,
    icon: AlertTriangle,
    name: "Units with Warnings",
    title: "Warning",
  },
  [EPropertyFilter.occupied]: {
    cssColorMethod: EFilterColorMethod.FILL,
    filterTypeEnum: EPropertyFilter.occupied,
    icon: Users,
    name: "Occupied Units",
    title: "Users",
  },
  [EPropertyFilter.vacant]: {
    cssColorMethod: EFilterColorMethod.FILL,
    filterTypeEnum: EPropertyFilter.vacant,
    icon: Key,
    name: "Vacant Units",
    title: "Key",
  },
};

const PropertyDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  let { buildingId } = params;
  if (!buildingId) {
    buildingId = "";
  }
  const [currentFilteredType, setFilteredType] = useState<EPropertyFilter>(
    EPropertyFilter.notFiltered
  );
  const [currentFilteredData, setFilteredData] = useState<
    IUnitDetailsDataHome[]
  >([]);
  let searchedData: IUnitDetailsDataHome[];
  let propertyTitle = "";
  let propertyAddress = "";
  const [searchValue, setSearchValue] = useState("");
  const { loading, data } = useQuery<IBuildingDetailsData, IBuildingIdVariable>(
    GET_BUILDING,
    {
      variables: { buildingId },
    }
  );
  useEffect(() => {
    if (!buildingId) {
      navigate("/");
    }
    window.scrollTo(0, 0);
  }, [buildingId]);
  useEffect(() => {
    setFilteredData(
      _.filter(searchedData, filteredMethods[currentFilteredType])
    );
  }, [data, searchValue, currentFilteredType]);
  useEffect(() => {
    smallHeaderTitle(propertyTitle);
    smallHeaderDescription(propertyAddress);
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderDescription("");
      smallHeaderBackArrow(false);
    };
  }, [data]);
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const crumbs = [
    {
      name: organizationIdName.organizationName,
      path: `/${organizationIdName.organizationId}/organization`,
    },
    { name: data.building.propertyName, path: buildingId },
  ];
  propertyTitle = data.building.propertyName;
  propertyAddress = `${data.building.addressLine1}, ${data.building.city}, ${data.building.state} ${data.building.zipcode}`;
  searchedData = _.filter(data.building.homes, (home) => {
    return home.propertyName.toLowerCase().includes(searchValue.toLowerCase());
  });
  const filteredDataAmounts: IObjectToIUnitDetailsDataHomeAmount = {
    [EPropertyFilter.notFiltered]: searchedData.length,
    [EPropertyFilter.withAlerts]: _.reduce(
      searchedData,
      (result, { alerts }) => {
        return alerts.length !== 0 ? result + 1 : result;
      },
      0
    ),
    [EPropertyFilter.withWarnings]: _.reduce(
      searchedData,
      (result, { warnings }) => {
        return warnings.length !== 0 ? result + 1 : result;
      },
      0
    ),
    [EPropertyFilter.occupied]: _.reduce(
      searchedData,
      (result, { numResidents }) => {
        return numResidents > 0 ? result + 1 : result;
      },
      0
    ),
    [EPropertyFilter.vacant]: _.reduce(
      searchedData,
      (result, { numResidents }) => {
        return numResidents === 0 ? result + 1 : result;
      },
      0
    ),
  };
  const homesByFloor = _.groupBy(currentFilteredData, "floor");
  const isPropertyListEmpty = currentFilteredData.length === 0;
  return (
    <div className="dashboard-container">
      <div className="dashboard-container-inner">
        <div className="property-details-breadcrumbs">
          <Breadcrumbs
            crumbs={crumbs}
            rootCrumb={{ name: "Organizations", path: "/" }}
          />
        </div>
        <div className="property-details-container">
          <div>
            <div className="property-description-container">
              <div className="property-description-head">
                <Properties
                  title="Property"
                  className="property-description-head-img"
                />
                <div>
                  <div className="unit-title hidden-name">{propertyTitle}</div>
                  <div className="unit-description">{propertyAddress}</div>
                </div>
              </div>
              <form
                className="search-container center-align-as-row"
                onSubmit={(evt: React.SyntheticEvent) => {
                  evt.preventDefault();
                }}
              >
                <Search title="Search" />
                <input
                  type="text"
                  data-testid="search-input"
                  placeholder="Search by unit name"
                  className="search-input tool-tip"
                  maxLength={50}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {searchValue !== "" && (
                  <button
                    type="button"
                    data-testid="search-input-clear"
                    className="tertiary-btn-small"
                    onClick={() => {
                      setSearchValue("");
                    }}
                  >
                    Clear
                  </button>
                )}
              </form>
              <FilterListSelect
                amount={filteredDataAmounts}
                currentFilterEnum={currentFilteredType}
                setFilterData={setFilteredType}
                FilterItemsData={FilterItemsData}
              />
              <FilterList
                amount={filteredDataAmounts}
                currentFilterEnum={currentFilteredType}
                setFilterData={setFilteredType}
                FilterItemsData={FilterItemsData}
              />
            </div>
          </div>
          {isPropertyListEmpty ? (
            <div className="center-align-as-column property-details-empty-floors-container">
              <LargeBuilding
                title="Building"
                className="property-details-floors-container-empty"
              />
              <p className="margin-top-small">
                There are no units matching your search
              </p>
            </div>
          ) : (
            <div className="property-details-floors-container">
              {Object.entries(homesByFloor).map(([floorValue, homes]) => {
                const sortedHomes = _.sortBy(homes, "propertyName");
                return (
                  <Floor
                    key={floorValue}
                    data={sortedHomes}
                    floorName={floorValue}
                    moreThanOneFloor={Object.entries(homesByFloor).length > 1}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
