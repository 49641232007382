import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "../../img/Arrow.svg";

export interface SettingsCardProps {
  link: string;
  settingName: string;
  numBuilding?: number;
}

const SettingsCard: React.FC<SettingsCardProps> = ({
  link,
  settingName,
  numBuilding,
}) => {
  return (
    <Link to={link} className="property-container">
      <div>
        <div className="unit-title hidden-name">{settingName}</div>
        {numBuilding !== undefined && (
          <div className="unit-description capital">
            {numBuilding} {numBuilding === 1 ? "property" : "properties"}
          </div>
        )}
      </div>
      <Arrow className="brilliant-card-installation-icon" />
    </Link>
  );
};
export default SettingsCard;
