import { Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import {
  emailType,
  maxLength1000,
  required,
} from "../../formValidation/FormValidation";
import InputField from "../registration/common/InputField";
import SubmitButton from "../buttons/SubmitButton";
import {
  clearInvitedUserError,
  inviteManager,
} from "../../actions/actionCreators/adminManagement";
import { EUserManagementError, IAppState } from "../../interfaces/interfaces";

interface IInviteManagerFormCustomProps {
  closeModalFn: () => void;
  refetch: () => void;
  invitedUserError: EUserManagementError;
  organizationId: string;
}

interface IInviteManagerFormData {
  email: string;
}

const InviteManagerForm: React.FC<
  IInviteManagerFormCustomProps &
    InjectedFormProps<IInviteManagerFormData, IInviteManagerFormCustomProps>
> = ({
  closeModalFn,
  handleSubmit,
  invalid,
  submitting,
  pristine,
  refetch,
  invitedUserError,
  organizationId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (value: IInviteManagerFormData, dispatch: Dispatch<any>) => {
    dispatch(inviteManager(value.email, closeModalFn, refetch, organizationId));
  };
  const showError = invitedUserError !== EUserManagementError.noError;
  const dispatch = useDispatch();
  const clearError = () => dispatch(clearInvitedUserError);
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="invite-manager-modal-section">
        <label htmlFor="emailField">Email</label>
        <br />
        <Field
          name="email"
          id="emailField"
          validate={[required, emailType, maxLength1000]}
          component={InputField}
          type="email"
          autoComplete="email"
          onChange={clearError}
        />
        {showError && (
          <p className="right-error">
            This user is already a member of the organization. Please edit the
            email address and try again.
          </p>
        )}
      </div>

      <div className="invite-manager-modal-actions">
        <button
          type="button"
          className="invite-manager-modal-cancel-btn tertiary-btn-small margin-right-smaller"
          onClick={closeModalFn}
        >
          Cancel
        </button>
        <SubmitButton
          disabled={invalid || submitting || pristine}
          name="Send Invite"
          decorator="primary-btn tertiary-btn-small tertiary-btn-small-main invite-manager-modal-actions-submit"
        />
      </div>
    </form>
  );
};

const ReduxForm = reduxForm<
  IInviteManagerFormData,
  IInviteManagerFormCustomProps
>({ form: "inviteManager" })(InviteManagerForm);

const mapStateToProps = (state: IAppState) => ({
  invitedUserError: state.userManagement.invitedUserError,
});

export default connect(mapStateToProps)(ReduxForm);
