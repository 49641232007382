import { NavLink, useParams } from "react-router-dom";
import { useRef, useState } from "react";

import Logo from "../../img/logos/Logo.svg";
import { ReactComponent as User } from "../../img/user.svg";
import { IUser } from "../../interfaces/interfaces";

import HeaderDropdown from "./HeaderDropdown";
import AlertBell from "./AlertBell";

export const renderLink = ({
  destinationPath,
  name,
}: {
  destinationPath: string;
  name: string;
}) => {
  return (
    <NavLink
      to={`/${destinationPath}`}
      end
      className={({ isActive }) =>
        isActive ? "header-menu-link header-menu-selected" : "header-menu-link"
      }
    >
      {name}
    </NavLink>
  );
};

const Header: React.FC<{
  user: IUser;
  enableConfigurationFlow?: boolean;
}> = ({ user }) => {
  const [isLogoutVisible, setLogoutVisibility] = useState(false);
  const { organizationId } = useParams();

  const headerButtonRef = useRef<null | HTMLDivElement>(null);
  // const { loading, data } = useQuery<IUserData, IUserParams>(GET_USER, {
  //   variables: { userId: user.userId },
  // });
  // if (loading) return null;
  // if (!data) throw Error("No data was returned");

  return (
    <div>
      <div className="header-container header-container-large">
        <NavLink to="/">
          <img className="header-logo" alt="header-logo" src={Logo} />
        </NavLink>
        <div ref={headerButtonRef} className="center-align-as-row">
          <button
            data-testid="header-name"
            aria-label="Show account actions"
            onClick={() => setLogoutVisibility(!isLogoutVisible)}
            type="button"
            className="header-name"
          >
            <span className="center-align-as-row">
              <span className="margin-right-smaller">
                {user.givenName} {user.familyName}
              </span>
              <User title="User" />
            </span>
          </button>
          {isLogoutVisible && (
            <HeaderDropdown
              headerButtonRef={headerButtonRef}
              closeHeaderDropdownComponent={() => setLogoutVisibility(false)}
            />
          )}
          {organizationId && <AlertBell />}
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  enableConfigurationFlow: false,
};

export default Header;
