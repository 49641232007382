import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import _ from "lodash";

import {
  GET_INTEGRATIONS_FOR_ORGANIZATION,
  GET_ORGANIZATION_BUILDINGS,
} from "../../api/gqlQueries";
import {
  IOrganizationData,
  IOrganizationParams,
  IPropertyData,
  IRemoteLockHome,
} from "../../interfaces/interfaces";
import {
  REMOTE_LOCK,
  sortPropertiesByName,
} from "../../utils/integrationUtils";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import BuildingDropdown from "../../utils/buildingDropdown";
import SectionTitleBar from "../common/SectionTitleBar";
import { ReactComponent as SmartLock } from "../../img/SmartLock.svg";
import Spinner from "../Spinner";
import {
  isEditAccessDevicesInProgressVar,
  organizationIdNameVar,
} from "../apollo/LocalState";

import RemoteLockMobileModal from "./modals/RemoteLockMobileModal";
import RemoteLockTable from "./RemoteLockTable";

const REMOTELOCK_LINK = "https://brilliant.remotelock.com/";

interface IOrganizationBuildings {
  organization: {
    buildings: IPropertyData[];
  };
}

export interface IRemoteLock {
  preselectedProperties?: IRemoteLockHome[];
}

const RemoteLock: React.FC<IRemoteLock> = ({ preselectedProperties = [] }) => {
  const params = useParams();
  const navigate = useNavigate();
  let { organizationId } = params;
  const { buildingId } = params;
  if (organizationId === undefined) {
    organizationId = "";
  }
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  const crumbs = [
    {
      name: organizationIdName.organizationName,
      path: `/${organizationIdName.organizationId}/organization`,
    },
    {
      name: "Integrations",
      path: `/${organizationIdName.organizationId}/integrations`,
    },
    { name: "RemoteLock", path: "" },
  ];

  // GraphQL requests
  const { loading, data: organizationData } = useQuery<
    IOrganizationBuildings,
    IOrganizationParams
  >(GET_ORGANIZATION_BUILDINGS, { variables: { organizationId } });
  const {
    loading: remoteLockIntegrationLoading,
    data: remoteLockIntegrationData,
  } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_INTEGRATIONS_FOR_ORGANIZATION,
    {
      variables: { organizationId },
    }
  );

  const isEditAccessDevicesInProgress = useReactiveVar(
    isEditAccessDevicesInProgressVar
  );

  // React Hooks
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>("");
  const [selectedProperties, setSelectedProperties] = useState<string[]>(
    preselectedProperties ? preselectedProperties.map((home) => home.id) : []
  );

  let sortedBuildings: IPropertyData[] = [];
  if (organizationData)
    sortedBuildings = sortPropertiesByName(
      organizationData.organization.buildings
    );

  useEffect(() => {
    if (sortedBuildings.length !== 0 && !buildingId)
      setSelectedBuildingId(sortedBuildings[0].id);
    if (sortedBuildings.length !== 0 && buildingId)
      setSelectedBuildingId(buildingId);
    if (preselectedProperties) {
      setSelectedProperties(preselectedProperties.map((home) => home.id));
    }
    return () => {
      isEditAccessDevicesInProgressVar(false);
    };
  }, [organizationData]);
  const disabled =
    selectedProperties.length > 0 || isEditAccessDevicesInProgress;
  if (loading || remoteLockIntegrationLoading) return <Spinner />;
  if (!organizationData || !remoteLockIntegrationData)
    throw Error("No data was returned");

  const remoteLockIntegration = _.find(
    remoteLockIntegrationData.organization.integrations,
    {
      integrationType: REMOTE_LOCK,
    }
  );

  return (
    <div className="padding-bottom-large center-align">
      <div className="element-tab-port-visible">
        <RemoteLockMobileModal
          closeMobileModal={() => navigate("/configurations")}
        />
      </div>
      <div className="remote-lock-container element-tab-port-hidden page-container-inner">
        <Breadcrumbs
          crumbs={crumbs}
          rootCrumb={{ name: "Organizations", path: "/" }}
        />
        <SectionTitleBar
          icon={SmartLock}
          title="RemoteLock"
          button={{
            isExternal: true,
            text: "RemoteLock Portal",
            url: REMOTELOCK_LINK,
          }}
        />
        <BuildingDropdown
          sortedBuildings={sortedBuildings}
          setSelectedBuildingId={setSelectedBuildingId}
          selectedBuildingId={selectedBuildingId}
          disabled={disabled}
        />
        {remoteLockIntegration && selectedBuildingId && (
          <RemoteLockTable
            unitAvailableDevices={remoteLockIntegration.devices}
            availableDoorDevices={
              remoteLockIntegration.doorGroups
                ? remoteLockIntegration.doorGroups
                : remoteLockIntegration.devices
            }
            buildingId={selectedBuildingId}
            selectedProperties={selectedProperties}
            setSelectedProperties={setSelectedProperties}
          />
        )}
      </div>
    </div>
  );
};

export default RemoteLock;
