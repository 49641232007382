import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import { cancelPortalUserInvite, removePortalUser } from "../../actions";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { SelectedTableEntry } from "../tables/UsersTables";
import { ReactComponent as Trash } from "../../img/Trash.svg";
import { selectedUserToShowVar } from "../apollo/LocalState";

export interface RemoveManagerModalProps {
  closeRemoveManagerModal: () => void;
  refetch: () => void;
  organizationName: string;
}

const RemoveManagerModal: React.FC<RemoveManagerModalProps> = ({
  closeRemoveManagerModal,
  refetch,
  organizationName,
}) => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const selectedUserToShow = useReactiveVar(selectedUserToShowVar);
  if (!selectedUserToShow) {
    return <></>;
  }

  const isRegisteredUser =
    Object.keys(selectedUserToShow).includes("givenName");
  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="invite-manager-modal-header">
          <div className="center-align-as-row">
            <Trash title="Trash" className="svg-icon-large" />
            <div className="invite-manager-modal-title unit-title">
              <b>Remove a Manager</b>
            </div>
          </div>
          <CloseIcon
            onClick={closeRemoveManagerModal}
            className="changes-header-img"
            title="Close modal"
          />
        </div>
        <div className="tenants-show-body">
          <div className="table-container margin-top-small">
            Confirming will remove this manager from the organization{" "}
            <b>{organizationName}</b>.
          </div>
          <div className="tenants-show-description-body">
            <SelectedTableEntry />
          </div>
          <div className="invite-manager-modal-actions margin-top-small">
            <button
              type="button"
              className="tertiary-btn-small tertiary-btn-small-alert margin-right-smaller"
              onClick={closeRemoveManagerModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-manager-modal-actions-submit"
              onClick={() => {
                if (isRegisteredUser) {
                  dispatch(
                    removePortalUser(
                      organizationId,
                      selectedUserToShow.id,
                      refetch
                    )
                  );
                } else {
                  dispatch(
                    cancelPortalUserInvite(
                      selectedUserToShow.emailAddress,
                      organizationId,
                      refetch
                    )
                  );
                }
                closeRemoveManagerModal();
              }}
            >
              Confirm Removal
            </button>
          </div>
          <div className="margin-top-small" />
        </div>
      </div>
    </div>
  );
};

export default RemoveManagerModal;
