import { connect, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "@apollo/client";

import {
  IAppState,
  ILoginData,
  IUserData,
  IUserParams,
} from "../../interfaces/interfaces";
import { ReactComponent as Close } from "../../img/Close.svg";
import { ReactComponent as Exit } from "../../img/logOut.svg";
import { GET_USER } from "../../api/gqlQueries";
import { ReactComponent as Logo } from "../../img/logos/Logo.svg";
import { ReactComponent as User } from "../../img/user.svg";
import { isSideMenuVisibleVar } from "../apollo/LocalState";
import { logOutUser } from "../../actions";

interface ISideMenuProps {
  user: ILoginData;
  enableConfigurationFlow?: boolean;
}

const renderLink = ({
  name,
  destinationPath,
}: {
  name: string;
  destinationPath: string;
}) => {
  return (
    <div className="side-menu-link-container">
      <NavLink
        to={`/${destinationPath}`}
        end
        className={({ isActive }) =>
          isActive
            ? "side-menu-link side-menu-selected"
            : "side-menu-link unit-title"
        }
        onClick={() => {
          isSideMenuVisibleVar(false);
        }}
      >
        {name}
      </NavLink>
    </div>
  );
};

const SideMenu: React.FC<ISideMenuProps> = ({ user }) => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery<IUserData, IUserParams>(GET_USER, {
    variables: { userId: user.userId },
  });

  const ref = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target))
        isSideMenuVisibleVar(false);
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (loading) return null;
  if (!data) throw Error("No data was returned");
  return (
    <div className="side-menu-container">
      <div ref={ref} className="side-menu-left">
        <div>
          <div className="side-menu-header-container">
            <Close
              title="close"
              className="side-menu-close"
              onClick={() => {
                isSideMenuVisibleVar(false);
              }}
            />
            <Logo title="logo" className="side-menu-logo" />
          </div>
          <div className="center-align-as-column">
            {renderLink({ destinationPath: "", name: "Homepage" })}
            {renderLink({
              destinationPath: "accountDetails",
              name: "Account Details",
            })}
          </div>
        </div>
        <div className="side-menu-user-info-container">
          <span className="center-align-as-row side-menu-user-info-img">
            <User title="User" />
            <span className="side-menu-user-info-name menu">
              {data.user.givenName} {data.user.familyName}
            </span>
          </span>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Exit onClick={() => dispatch<any>(logOutUser())} title="Log Out" />
        </div>
      </div>
      <div className="side-menu-right" />
    </div>
  );
};

SideMenu.defaultProps = {
  enableConfigurationFlow: false,
};

const mapStateToProps = (state: IAppState) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(SideMenu);
