import { useQuery, useReactiveVar } from "@apollo/client";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  EPermissionActions,
  IAppState,
  IOrganizationParams,
  IPropertyData,
  IUserData,
  IUserParams,
} from "../../interfaces/interfaces";
import { EPermissions, hasPermission } from "../../utils/rolesAndPermissions";
import { GET_ORGANIZATION_SUMMARY, GET_USER } from "../../api/gqlQueries";
import { ReactComponent as Key } from "../../img/key.svg";
import { ReactComponent as Properties } from "../../img/Properties.svg";
import { RenderEmptyHomeView } from "../../utils/utils";
import Spinner from "../Spinner";
import {
  organizationIdNameVar,
  smallHeaderBackArrow,
  smallHeaderTitle,
  userIdVar,
} from "../apollo/LocalState";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

import SettingsCard from "./SettingsCard";
import Menu from "./Menu";
import BuildingCard from "./BuildingCard";

interface IOrganizationData {
  organization: {
    numHomes: number;
    buildings: IPropertyData[];
  };
}

const OrganizationDetails: React.FC = () => {
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  let { organizationId } = useParams();
  if (organizationId === undefined) {
    organizationId = "";
  }
  const userId = useReactiveVar(userIdVar);
  const { loading, data } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_ORGANIZATION_SUMMARY,
    { variables: { organizationId } }
  );
  const { loading: userLoad, data: userData } = useQuery<
    IUserData,
    IUserParams
  >(GET_USER, {
    variables: { userId },
  });
  useEffect(() => {
    smallHeaderTitle(organizationIdName.organizationName);
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, [data]);
  if (loading || userLoad) return <Spinner />;
  if (!data || !userData) throw Error("No data was returned");

  const crumbs = [{ name: organizationIdName.organizationName, path: "" }];

  const { buildings, numHomes } = data.organization;
  const numBuildings = buildings.length;
  return (
    <div className="dashboard-container">
      <div className="dashboard-container-inner">
        <Breadcrumbs
          crumbs={crumbs}
          rootCrumb={{ name: "Organizations", path: "/" }}
        />
        <div className="dashboard-settings-container">
          <Menu
            icon={Properties}
            amount={numBuildings}
            name="Properties"
            singular="Property"
          />
          <Menu
            icon={Key}
            amount={numHomes}
            name="Rental Units"
            singular="Rental Unit"
          />
        </div>
        <div className="options">
          <div className="options-name menu">Properties</div>
          {numBuildings === 0 ? (
            <RenderEmptyHomeView
              icon={Properties}
              iconClassName="dashboard-empty-img"
              iconTitle="Empty Properties"
              componentTitle={
                "You haven't added any properties to Brilliant yet"
              }
              componentDescription="To add a property, contact Brilliant or link your property management software (PMS) system."
            />
          ) : (
            <div className="options-properties">
              {buildings.map((building: IPropertyData) => {
                return <BuildingCard key={building.id} property={building} />;
              })}
            </div>
          )}

          <>
            <div className="options-name menu">Settings</div>
            <div className="options-properties">
              <SettingsCard
                link={`/${organizationId}/alertPreferences`}
                settingName="Alert Preferences"
              />
              {hasPermission(
                userData.user.role,
                EPermissions.CONFIGURATIONS,
                EPermissionActions.VIEW
              ) && (
                <SettingsCard
                  link={`/${organizationId}/configurations`}
                  settingName="Configurations"
                />
              )}
              <SettingsCard
                link={`/${organizationId}/managers`}
                settingName="Property Managers"
              />
              <SettingsCard
                link={`/${organizationId}/integrations`}
                settingName="Integrations"
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(OrganizationDetails);
