import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFeature } from "@optimizely/react-sdk";
import _ from "lodash";
import { useParams } from "react-router-dom";

import {
  GET_DOOR_GROUPS_AND_UNIT_ACCESS_DEVICES_FOR_HOME,
  GET_INVITED_RESIDENT_CARD_INFO,
  GET_RESIDENT_CARD_INFO,
  GET_SMART_CARD_NUMBERS,
  UPDATE_CARD_CREDENTIALS,
} from "../../../api/gqlQueries";
import Spinner from "../../Spinner";
import { ReactComponent as InfoIcon } from "../../../img/infoIcon.svg";
import {
  IAccessDevice,
  IOrganizationParams,
  IPropertyIdVariable,
  IRemoteLockIntegrationDevice,
  ISmartCardCredentialsData,
} from "../../../interfaces/interfaces";
import EWebAPIError from "../../../api/errors";
import RedAlertMessage from "../../common/RedAlertMessage";
import { SMART_CARD_LOCK_TYPE } from "../../../utils/utils";

interface IDoorGroups {
  id: string;
  remotelockDoorGroups: IRemoteLockIntegrationDevice[];
  remotelockUnitAccessDevices: IAccessDevice[];
}

interface IsDoorGroupsHome {
  home: IDoorGroups;
}

interface IOrganizationSmartCardCredentialsData {
  organization: {
    id: string;
    smartCardCredentialsV2: ISmartCardCredentialsData[];
  };
}

const renderSelectOptions = (
  smartCardCredential: ISmartCardCredentialsData
) => (
  <option
    className="scheduler-select-option menu"
    key={`${smartCardCredential.id}`}
    value={JSON.stringify(smartCardCredential)}
  >
    {smartCardCredential.badgeNumber}
  </option>
);

const AccessCredentials: React.FC<{
  emailAddress: string;
  propertyId: string;
  hasInvitation: boolean;
  userId: string;
  closeRemoveTenantModal: () => void;
  isSmartCardCredentialsEnabledStories?: boolean;
}> = ({
  propertyId,
  emailAddress,
  hasInvitation,
  userId,
  closeRemoveTenantModal,
  isSmartCardCredentialsEnabledStories = false,
}) => {
  const [errorId, setErrorId] = useState<number | null>(null);
  const params = useParams();
  let { organizationId } = params;
  if (organizationId === undefined) {
    organizationId = "";
  }

  const commonErrorMessages: { [key: number]: string } = {
    [EWebAPIError.TOO_MANY_REQUESTS]:
      "The system is currently having issues. Please try again later.",
  };
  const cardNumberErrors: { [key: number]: string } = {
    [EWebAPIError.REMOTELOCK_CARD_NUMBER_ALREADY_TAKEN]:
      "This card number has already been used.",
    [EWebAPIError.REMOTELOCK_CARD_NUMBER_IS_NOT_VALID]:
      "Please enter a valid card number.",
  };
  const smartCardCredentialsErrors: { [key: number]: string } = {
    [EWebAPIError.REMOTELOCK_SMART_CARD_ALREADY_TAKEN]:
      "This card credential has already been used.",
  };
  const [isSmartCardCredentialsEnabled] = useFeature("smart_card_number");
  const { loading, data } = useQuery<IsDoorGroupsHome, IPropertyIdVariable>(
    GET_DOOR_GROUPS_AND_UNIT_ACCESS_DEVICES_FOR_HOME,
    {
      variables: { propertyId },
    }
  );
  const { data: smartCardOrganization, loading: smartCardOrganizationLoading } =
    useQuery<IOrganizationSmartCardCredentialsData, IOrganizationParams>(
      GET_SMART_CARD_NUMBERS,
      {
        variables: { organizationId },
      }
    );
  const { data: residentCardNumber, loading: residentCardNumberLoading } =
    useQuery(GET_RESIDENT_CARD_INFO, {
      skip: hasInvitation,
      variables: { propertyId, userId },
    });
  const {
    data: invitedResidentCardNumber,
    loading: invitedResidentCardNumberLoading,
  } = useQuery(GET_INVITED_RESIDENT_CARD_INFO, {
    skip: !hasInvitation,
    variables: { emailAddress, propertyId },
  });
  const initialSmartCardCredentials = { badgeNumber: "", id: "" };
  const cardObject =
    residentCardNumber?.resident || invitedResidentCardNumber?.invitedResident;
  const smartCardCredentials = cardObject?.smartCardCredentials;
  const cardNumber = cardObject?.remotelockCardNumber;
  const [credentialInputValue, setCredentialInputValue] = useState("");
  const [smartCardCredentialInputValue, setSmartCardCredentialInputValue] =
    useState<ISmartCardCredentialsData>(initialSmartCardCredentials);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  useEffect(() => {
    if (cardNumber) {
      setCredentialInputValue(cardNumber);
    }
    if (smartCardCredentials) {
      setSmartCardCredentialInputValue(smartCardCredentials);
    }
  }, [cardNumber, smartCardCredentials]);
  useEffect(() => {
    if (cardNumber !== undefined && smartCardCredentials !== undefined) {
      setSaveButtonDisabled(
        credentialInputValue === cardNumber &&
          smartCardCredentialInputValue.badgeNumber ===
            smartCardCredentials.badgeNumber
      );
    }
  }, [credentialInputValue, smartCardCredentialInputValue]);
  const [updateCardCredentials, { loading: cardCredentialLoading }] =
    useMutation(UPDATE_CARD_CREDENTIALS);
  if (
    loading ||
    cardCredentialLoading ||
    smartCardOrganizationLoading ||
    invitedResidentCardNumberLoading ||
    residentCardNumberLoading
  )
    return <Spinner />;
  if (!data || !smartCardOrganization) throw Error("No data was returned");
  const isCardNumberFieldEnabled =
    data.home.remotelockDoorGroups.length ||
    data.home.remotelockUnitAccessDevices.length;
  const unitAccessDevices = _.map(
    data.home.remotelockUnitAccessDevices,
    (unitAccessDevice) => {
      return unitAccessDevice.device.accessibleType;
    }
  );
  const hasSchlageLock = unitAccessDevices.includes(SMART_CARD_LOCK_TYPE);
  return (
    <>
      <div className="tenants-show-credentials-container margin-left-right-small">
        <p>
          Use the fields below to submit this resident&apos;s personal access
          credentials for unlocking RemoteLock access devices assigned to this
          unit.
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="tenants-show-credentials-form"
      >
        <div className="tenants-show-credentials-container tenants-show-credentials-container-flex margin-left-right-small">
          <div className="tenants-show-credentials-container-outer">
            <div className="tenants-show-credentials-container-inner">
              <div className="text-label tenants-show-credentials-label">
                Card Number
              </div>
              <div className="tooltip">
                <InfoIcon className="table-row-header-svg center-align-as-row" />
                <div className="tooltiptext unit-description">
                  Submit the ID number on this resident&apos;s Prox Card or Fob.
                </div>
              </div>
            </div>
            {isCardNumberFieldEnabled ? (
              <>
                <input
                  className={`tenants-show-credentials-input ${
                    errorId && errorId in cardNumberErrors && "alert"
                  }`}
                  type="number"
                  data-testid="unit-input"
                  maxLength={50}
                  pattern="[0-9]*"
                  value={credentialInputValue}
                  onChange={(e) =>
                    setCredentialInputValue((v: string) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                />
                {errorId && (
                  <p className="alert">{cardNumberErrors[errorId]}</p>
                )}
              </>
            ) : (
              <div
                data-testid="no-common-area-access-assigned"
                className="tool-tip inactive-font"
              >
                No compatible devices were assigned to this unit
              </div>
            )}
          </div>
          {(isSmartCardCredentialsEnabled ||
            isSmartCardCredentialsEnabledStories) && (
            <div className="tenants-show-credentials-container-outer">
              <div className="tenants-show-credentials-select-container">
                <div className="tenants-show-credentials-container-inner">
                  <div className="text-label tenants-show-credentials-label">
                    Smart Card Credential
                  </div>
                  <div className="tooltip">
                    <InfoIcon className="table-row-header-svg center-align-as-row" />
                    <div className="tooltiptext unit-description">
                      If your card number does not appear, hold card to MT20W
                      reader and try again.
                    </div>
                  </div>
                </div>
                {hasSchlageLock ? (
                  <select
                    data-testid="smart-card-credential-select"
                    onChange={(e) => {
                      setSmartCardCredentialInputValue(
                        JSON.parse(e.target.value)
                      );
                    }}
                    className={`tenants-show-credentials-select ${
                      smartCardCredentialInputValue.badgeNumber === "" &&
                      "tenants-show-credentials-select-default"
                    } menu tool-tip ${
                      errorId &&
                      errorId in smartCardCredentialsErrors &&
                      "alert"
                    }`}
                    value={JSON.stringify(smartCardCredentialInputValue)}
                  >
                    <option
                      className="scheduler-select-option menu"
                      key="Select"
                      value={JSON.stringify(initialSmartCardCredentials)}
                    >
                      Select
                    </option>
                    {smartCardOrganization.organization.smartCardCredentialsV2.map(
                      renderSelectOptions
                    )}
                  </select>
                ) : (
                  <div className="tool-tip inactive-font">
                    No compatible devices were assigned to this unit
                  </div>
                )}
              </div>
              {errorId && (
                <p className="alert">{smartCardCredentialsErrors[errorId]}</p>
              )}
            </div>
          )}
        </div>

        {smartCardCredentials !== undefined &&
          smartCardCredentialInputValue.badgeNumber !==
            smartCardCredentials.badgeNumber &&
          smartCardCredentialInputValue.badgeNumber !== "" && (
            <div className="margin-left-right-small">
              <RedAlertMessage>
                <div className="tenants-show-credentials-red-alert-text-container">
                  <div className="menu margin-bottom-very-small">
                    Credential Step Required
                  </div>
                  <p>
                    After submitting this form, the selected card must be held
                    to the MT20W reader to finish saving these changes to the
                    card.
                  </p>
                </div>
              </RedAlertMessage>
            </div>
          )}
        <div className="tenants-show-credentials-btn-container">
          {errorId && <p className="alert">{commonErrorMessages[errorId]}</p>}
          <button
            type="submit"
            data-testid="save-btn"
            className="tenants-show-credentials-btn tertiary-btn-small user-card-action-btn-resend"
            disabled={saveButtonDisabled}
            onClick={() => {
              updateCardCredentials({
                update: (cache, { data: responseData }) => {
                  if (!responseData) {
                    return;
                  }
                  cache.modify({
                    fields: {
                      remotelockCardNumber() {
                        return responseData.updateCardCredentials.cardNumber;
                      },
                      smartCardCredentials() {
                        return responseData.updateCardCredentials
                          .smartCardCredential;
                      },
                    },
                    id: cache.identify(cardObject),
                  });
                  closeRemoveTenantModal();
                },
                variables: {
                  cardNumber: credentialInputValue,
                  emailAddress,
                  propertyId,
                  smartCardId: smartCardCredentialInputValue.id,
                },
              }).catch((err) => {
                if (
                  err.networkError.result.error_id ===
                  EWebAPIError.REMOTELOCK_SMART_CARD_DOES_NOT_EXIST
                ) {
                  setErrorId(EWebAPIError.TOO_MANY_REQUESTS);
                } else {
                  setErrorId(err.networkError.result.error_id);
                }
              });
            }}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default AccessCredentials;
