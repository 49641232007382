import { useState } from "react";

import {
  DeviceStatus,
  DeviceTypes,
  IIntegrationDevices,
  IIntegrationsCard,
} from "../../interfaces/interfaces";
import BaseModal from "../modals/BaseModal";
import { ReactComponent as ButterflyMX } from "../../img/3PDevices/BMX.svg";
import { ReactComponent as DoorBell } from "../../img/3PDevices/DoorBell.svg";
import { ReactComponent as GenericLight } from "../../img/3PDevices/GenericLight.svg";
import { ReactComponent as GenericOutlet } from "../../img/3PDevices/GenericOutlet.svg";
import { ReactComponent as LeakDetector } from "../../img/3PDevices/LeakDetector.svg";
import { ReactComponent as Music } from "../../img/3PDevices/Music.svg";
import { ReactComponent as SmartLock } from "../../img/SmartLock.svg";
import { ReactComponent as Thermostat } from "../../img/3PDevices/Thermostat.svg";
import { ReactComponent as UnknownDevice } from "../../img/3PDevices/UnknownDevice.svg";
import { ReactComponent as WaterValve } from "../../img/3PDevices/WaterValve.svg";
import { quantityDisplay } from "../../utils/utils";

const integrationDeviceTypeToImage = ({
  deviceType,
}: {
  deviceType: string;
}) => {
  switch (deviceType) {
    case DeviceTypes.LOCK:
      return <SmartLock />;
    case DeviceTypes.OFFLINE_LOCK:
      return <SmartLock />;
    case DeviceTypes.THERMOSTAT:
      return <Thermostat />;
    case DeviceTypes.CLIMATE_SENSOR:
      return <LeakDetector />;
    case DeviceTypes.BUILDING_ENTRY_PANEL:
      return <ButterflyMX />;
    case DeviceTypes.OUTLET:
      return <GenericOutlet />;
    case DeviceTypes.LIGHT:
      return <GenericLight />;
    case DeviceTypes.WATER_SHUTOFF_VALVE:
      return <WaterValve />;
    case DeviceTypes.MUSIC:
      return <Music />;
    case DeviceTypes.DOORBELL:
      return <DoorBell />;
    default:
      return <UnknownDevice />;
  }
};

export interface IntegrationCardProps {
  data: IIntegrationsCard;
  isSmartCardCredentialsEnabledStories?: boolean;
  isReconfiguring: boolean;
  IsEveryControlOffline: boolean;
}

const renderOfflineDevicesAmount = (
  offlineDevices: IIntegrationDevices[],
  totalNumOfIntegrationDevices: number
) => {
  return (
    <div className="brilliant-card-installation-error">
      {`${
        offlineDevices.length
      } of ${totalNumOfIntegrationDevices}  ${quantityDisplay(
        offlineDevices.length,
        "Devices"
      )} Offline`}
    </div>
  );
};

const displayDeviceType = (integrationDevice: IIntegrationDevices) => {
  if (integrationDevice.deviceType === DeviceTypes.LOCK) {
    return (
      <div className="unit-description-small">
        {integrationDevice.isCommonArea ? "COMMON AREA" : "UNIT"}
      </div>
    );
  }
  return (
    <div className="unit-description-small">
      {integrationDevice.deviceType === DeviceTypes.CLIMATE_SENSOR
        ? "LEAK DETECTOR"
        : integrationDevice.deviceType.replace(/_/g, " ")}
    </div>
  );
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  data,
  isReconfiguring,
  IsEveryControlOffline,
}) => {
  const [showDevicesDetail, setShowDevicesDetail] = useState(false);
  const offlineDevices = data.integrationDevices.filter((integrationDevice) => {
    return integrationDevice.connectivityStatus !== DeviceStatus.Online;
  });
  const OfflineDevices = data.integrationDevices.find(
    (integrationDevice) =>
      integrationDevice.connectivityStatus === DeviceStatus.Offline
  );
  const offlineHoneyWellDevices = !IsEveryControlOffline && OfflineDevices;
  const totalNumOfIntegrationDevices = data.integrationDevices.length;
  return (
    <>
      {showDevicesDetail && (
        <BaseModal
          headerText={
            IsEveryControlOffline
              ? "Unresponsive Devices"
              : `${data.name} Devices`
          }
          closeModalFn={() => setShowDevicesDetail(false)}
          actionButtonFn={() => undefined}
        >
          <div className="brilliant-card-details-container">
            {IsEveryControlOffline && (
              <div className="margin-bottom-small">
                <p className="margin-bottom-small">
                  We have not been able to reach
                  <b> {data.name} </b>
                  devices in this unit, please check the connectivity of the
                  Brilliant Control(s) in this unit. It may take up to 30
                  minutes for these devices to reconnect.
                </p>
                <p>
                  Please note that you may have to access the unit to resolve
                  the issue.
                </p>
              </div>
            )}
            {offlineHoneyWellDevices && data.name === "Honeywell Home" && (
              <p className="margin-bottom-small">
                You appear to have device(s) offline. Please note that you may
                have to access the unit to resolve the issue.
              </p>
            )}
            {data.integrationDevices.map((integrationDevice) => {
              return (
                <div className="brilliant-card-details-description-container">
                  {integrationDeviceTypeToImage({
                    deviceType: integrationDevice.deviceType,
                  })}
                  <div>
                    <div className="unit-title hidden-name hidden-name-1">
                      {integrationDevice.name}
                    </div>
                    {displayDeviceType(integrationDevice)}
                    {integrationDevice.connectivityStatus !==
                      DeviceStatus.Online &&
                      !IsEveryControlOffline && (
                        <div className="brilliant-card-installation-error">
                          Offline
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        </BaseModal>
      )}
      <button
        type="button"
        className="brilliant-card-container"
        onClick={() => {
          if (!isReconfiguring) setShowDevicesDetail(true);
        }}
        disabled={isReconfiguring}
      >
        <div className="brilliant-card-description">
          <div className="unit-title hidden-name">{data.name}</div>
          <div className="brilliant-card-description-id unit-description-small flex-display uppercase">
            {`${data.numDevices} ${quantityDisplay(data.numDevices, "DEVICE")}`}
          </div>
          {offlineDevices.length > 0 &&
            !IsEveryControlOffline &&
            renderOfflineDevicesAmount(
              offlineDevices,
              totalNumOfIntegrationDevices
            )}
          {IsEveryControlOffline && (
            <div className="brilliant-card-installation-error">
              UNRESPONSIVE
            </div>
          )}
          {isReconfiguring && (
            <div className="brilliant-card-description-reconfiguring unit-device-id">
              RECONFIGURING...
            </div>
          )}
        </div>
      </button>
    </>
  );
};
export default IntegrationCard;
