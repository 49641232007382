import { EPermissionActions, Role } from "../interfaces/interfaces";

export enum EPermissions {
  CONFIGURATIONS = "configurations",
  ORGANIZATIONS = "organizations",
}

type Permissions = {
  configurations: {
    action: EPermissionActions.VIEW;
  };
  organizations: {
    action:
      | EPermissionActions.VIEW
      | EPermissionActions.CREATE
      | EPermissionActions.UPDATE;
  };
};

export const ROLES = {
  mdu_brilliant_admin: {
    configurations: {
      view: true,
    },
    organizations: {
      create: true,
      update: true,
      view: true,
    },
  },
  mdu_operator: {
    organizations: {
      view: true,
    },
  },
  superuser: {
    configurations: {
      view: true,
    },
    organizations: {
      create: true,
      update: true,
      view: true,
    },
  },
};

type RolesWithPermissions = {
  [R in Role]: Partial<{
    [Key in keyof Permissions]: Partial<{
      [Action in Permissions[Key]["action"]]: boolean;
    }>;
  }>;
};

export function hasPermission<Resource extends keyof Permissions>(
  role: Role,
  resource: Resource,
  action: Permissions[Resource]["action"]
) {
  const permission = (ROLES as RolesWithPermissions)[role][resource]?.[action];
  if (permission == null) return false;

  return permission;
}
