import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { GET_USER } from "../../api/gqlQueries";
import {
  IAppState,
  ILoginData,
  IUserData,
  IUserParams,
} from "../../interfaces/interfaces";
import { organizationIdNameVar } from "../apollo/LocalState";

import Header from "./Header";
import HeaderSmall from "./HeaderSmall";

const HeaderHelper: React.FC<{
  user: ILoginData;
}> = ({ user }) => {
  const { organizationId } = useParams();
  if (organizationId) {
    organizationIdNameVar({
      organizationId,
      organizationName: "",
    });
  }
  const { loading, data } = useQuery<IUserData, IUserParams>(GET_USER, {
    variables: { userId: user.userId },
  });
  if (loading) return null;
  if (!data) throw Error("No data was returned");

  if (organizationId) {
    const selectedOrganization = data.user.organizations.find(
      (organization) => organization.id === organizationId
    );
    if (selectedOrganization) {
      organizationIdNameVar({
        organizationId: selectedOrganization.id,
        organizationName: selectedOrganization.name,
      });
    }
  }
  return (
    <>
      <Header user={data.user} />
      <HeaderSmall />
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(HeaderHelper);
