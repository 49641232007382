import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { cloneDeep } from "lodash";
import { useState } from "react";

import { CREATE_ORGANIZATION, GET_USER } from "../../api/gqlQueries";
import {
  EPermissionActions,
  IUserData,
  IUserParams,
} from "../../interfaces/interfaces";
import { EPermissions, hasPermission } from "../../utils/rolesAndPermissions";
import BaseRegistrationModal from "../modals/BaseRegistrationModal";
import { ReactComponent as Properties } from "../../img/Properties.svg";
import { RenderEmptyHomeView } from "../../utils/utils";
import { ReactComponent as Settings } from "../../img/settings.svg";
import Spinner from "../Spinner";
import { userIdVar } from "../apollo/LocalState";

import SettingsCard from "./SettingsCard";

const CreateOrganizationForm: React.FC<{
  newOrganizationValue: string;
  setNewOrganizationValue: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}> = ({ newOrganizationValue, setNewOrganizationValue, closeModal }) => {
  const userId = useReactiveVar(userIdVar);
  const [createOrganization] = useMutation(CREATE_ORGANIZATION);
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const form = e.currentTarget;
    const formData = new FormData(form);
    const organizationName = formData.get("organization-name") as string;
    createOrganization({
      update: (cache, { data }) => {
        if (!data) {
          // No need to update cache if no data returned.
          return;
        }
        const cachedData = cache.readQuery<IUserData, IUserParams>({
          query: GET_USER,
          variables: { userId },
        });
        if (!cachedData) {
          return;
        }
        const updatedData = cloneDeep(cachedData);
        updatedData.user.organizations.push(
          data.createOrganization.organization
        );
        cache.writeQuery({
          data: updatedData,
          query: GET_USER,
          variables: { userId },
        });
        closeModal();
      },
      variables: { organizationName, userId },
    });
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-create-body-inner">
        <div className="modal-input-container">
          <label
            htmlFor="organization-name"
            data-testid="create-organization-name"
          >
            Organization Name
          </label>
          <input
            className="modal-input"
            name="organization-name"
            value={newOrganizationValue}
            onChange={(e) => setNewOrganizationValue(e.target.value)}
          />
        </div>
      </div>
      <div className="margin-bottom-small" />
      <div className="modal-input-actions">
        <button
          type="button"
          className="tertiary-btn-small"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          disabled={newOrganizationValue === ""}
          type="submit"
          className="tertiary-btn-small user-card-action-btn user-card-action-btn-resend"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

const CreateOrganizationModal: React.FC<{
  newOrganizationValue: string;
  setNewOrganizationValue: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}> = ({ closeModal, newOrganizationValue, setNewOrganizationValue }) => {
  return (
    <BaseRegistrationModal
      closeModalFn={closeModal}
      headerText="Create Organization"
      Image={<Settings className="organization-create-img" />}
    >
      <CreateOrganizationForm
        closeModal={closeModal}
        newOrganizationValue={newOrganizationValue}
        setNewOrganizationValue={setNewOrganizationValue}
      />
    </BaseRegistrationModal>
  );
};

const Organizations: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [newOrganizationValue, setNewOrganizationValue] = useState("");
  const userId = useReactiveVar(userIdVar);
  const { loading: userLoad, data: userData } = useQuery<
    IUserData,
    IUserParams
  >(GET_USER, {
    variables: { userId },
  });
  if (userLoad) return <Spinner />;
  if (!userData) throw Error("No data was returned");
  const { organizations } = userData.user;
  const numOrganizations = organizations.length;

  return (
    <div className="dashboard-container">
      {showModal && (
        <CreateOrganizationModal
          newOrganizationValue={newOrganizationValue}
          setNewOrganizationValue={setNewOrganizationValue}
          closeModal={() => setShowModal(false)}
        />
      )}
      <div className="dashboard-container-inner">
        <div className="options">
          <div className="dashboard-name-container">
            <div className="options-name menu">Organizations</div>
            {hasPermission(
              userData.user.role,
              EPermissions.ORGANIZATIONS,
              EPermissionActions.CREATE
            ) && (
              <button
                className="dashboard-name-button"
                type="button"
                data-testid="create-organization-btn"
                onClick={() => setShowModal(true)}
              >
                + Create Organization
              </button>
            )}
          </div>
          {numOrganizations === 0 ? (
            <RenderEmptyHomeView
              icon={Properties}
              iconClassName="dashboard-empty-img"
              iconTitle="Empty Properties"
              componentTitle="No Organizations"
            />
          ) : (
            <div className="options-properties">
              {organizations.map((organization) => {
                return (
                  <div key={organization.id}>
                    <SettingsCard
                      link={`/${organization.id}/organization`}
                      numBuilding={organization.numBuildings}
                      settingName={organization.name}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Organizations;
