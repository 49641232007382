import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import { ReactComponent as Settings } from "../../img/settings.svg";
import InviteManagerModal from "../header/InviteManagerModal";
import { UsersTables } from "../tables/UsersTables";
import { GET_PORTAL_USERS_INFO } from "../../api/gqlQueries";
import {
  IInvitedUser,
  IOrganizationIdVariable,
  IRegisteredUser,
  IShowPortalUsersData,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import {
  organizationIdNameVar,
  selectedUserToShowVar,
  smallHeaderBackArrow,
  smallHeaderTitle,
} from "../apollo/LocalState";

import RemoveManagerModal from "./RemoveManagerModal";

const EditManagers: React.FC = () => {
  const params = useParams();
  let { organizationId } = params;
  if (organizationId === undefined) {
    organizationId = "";
  }
  const organizationIdName = useReactiveVar(organizationIdNameVar);
  useEffect(() => {
    smallHeaderTitle("Property Managers");
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, []);
  const [showInviteManagerModal, setShowInviteManagerModal] = useState(false);
  const [showRemoveManagerModal, setRemoveManagerModal] = useState(false);
  const openRemoveManagerModal = (
    user: IRegisteredUser | IInvitedUser | null
  ) => {
    selectedUserToShowVar(user);
    setRemoveManagerModal(true);
  };
  const { loading, data, refetch } = useQuery<
    IShowPortalUsersData,
    IOrganizationIdVariable
  >(GET_PORTAL_USERS_INFO, {
    variables: { organizationId },
  });
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const crumbs = [
    {
      name: organizationIdName.organizationName,
      path: `/${organizationIdName.organizationId}/organization`,
    },
    { name: "Property Managers", path: "" },
  ];
  const shouldEmptyTableRender =
    data.organization.portalUsers.length === 0 &&
    data.organization.invitedPortalUsers.length === 0;
  return (
    <div className="managers-container center-align">
      {showInviteManagerModal && (
        <InviteManagerModal
          closeModalFn={() => setShowInviteManagerModal(false)}
          refetch={refetch}
          organizationId={organizationId}
        />
      )}
      {showRemoveManagerModal && (
        <RemoveManagerModal
          closeRemoveManagerModal={() => setRemoveManagerModal(false)}
          refetch={refetch}
          organizationName={data.organization.name}
        />
      )}
      <div className="managers-container-inner padding-bottom-large">
        <div className="property-details-breadcrumbs">
          <Breadcrumbs
            crumbs={crumbs}
            rootCrumb={{ name: "Organizations", path: "/" }}
          />
        </div>
        <div className="managers-header-container">
          <div className="managers-header unit-title">
            <Settings title="Settings" className="managers-header-icon" />
            Edit Managers
          </div>
        </div>
        <div className="managers-description menu">
          The following users are managers of the {data.organization.name}{" "}
          organization
        </div>
        <div className="margin-top-small" />
        <div className="managers-description-table">
          <UsersTables
            invitedPortalUsers={data.organization.invitedPortalUsers}
            portalUsers={data.organization.portalUsers}
            shouldEmptyTableRender={shouldEmptyTableRender}
            openRemoveFlowModal={openRemoveManagerModal}
            shouldTrashRender
            shouldGearRender={false}
          />
        </div>
        <button
          onClick={() => setShowInviteManagerModal(true)}
          type="button"
          className="primary-btn managers-btn"
        >
          Invite Manager
        </button>
      </div>
    </div>
  );
};

export default EditManagers;
