import _ from "lodash";

import {
  IIntegrations,
  IIntegrationsNameAndLink,
  IPropertyData,
  IRemoteLockBuilding,
  IRemoteLockHome,
  IRemoteLockIntegration,
  IRemoteLockIntegrationDevice,
} from "../interfaces/interfaces";
import {
  ADD_REMOTELOCK_DEVICES_TO_PROPERTIES,
  GET_CONFIGURATIONS,
  GET_INTEGRATIONS_FOR_ORGANIZATION,
  GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
  GET_ORGANIZATION_BUILDINGS,
  GET_REMOTE_LOCK_BUILDING,
} from "../api/gqlQueries";

/*
 * Constants
 */
export const ENTRATA = "ENTRATA";
export const REMOTE_LOCK = "REMOTELOCK";
export const REAL_PAGE = "REALPAGE";
export const LOCK = "LOCK";

export const ENTRATA_LINK = "https://www.entrata.com/";
export const OpenRealPageLink =
  "https://www.realpage.com/login/identity/Account/SignIn";
export const remoteLockDeviceType = {
  doorGroup: "DOOR_GROUP",
  unitDevice: "UNIT_ACCESS_DEVICE",
};

export const REMOTE_LOCK_LARGE_UPDATE_THRESHOLD = 30;

/*
 * Methods
 */

export const renderIntegrationInfo = ({
  integrationType,
}: {
  integrationType: IIntegrations;
}): IIntegrationsNameAndLink => {
  if (integrationType.entrataIntegration) {
    return {
      landingURL: "https://www.entrata.com",
      name: "Entrata",
    };
  }
  if (integrationType.realPageIntegration) {
    return {
      landingURL: OpenRealPageLink,
      name: "RealPage",
    };
  }
  return { landingURL: "", name: "" };
};

export const checkIntegrations = (
  integrations: IRemoteLockIntegration[]
): IIntegrations => {
  let remoteLockIntegration = false;
  let entrataIntegration = false;
  let realPageIntegration = false;

  _.forEach(integrations, (element) => {
    if (element.integrationType === REMOTE_LOCK) {
      remoteLockIntegration = true;
    }
    if (element.integrationType === REAL_PAGE) {
      realPageIntegration = true;
    }
    if (element.integrationType === ENTRATA) {
      entrataIntegration = true;
    }
  });
  return { entrataIntegration, realPageIntegration, remoteLockIntegration };
};

export const hasPMSIntegration = (
  integrations: IRemoteLockIntegration[]
): boolean => {
  const integrationType = checkIntegrations(integrations);
  const { entrataIntegration, realPageIntegration } = integrationType;
  return entrataIntegration || realPageIntegration;
};

const getExternalPopupURL = (organizationId: string): string => {
  const REDIRECT_URI = encodeURIComponent(
    `${process.env.REACT_APP_REMOTE_LOCK_REDIRECT_URI}`
  );
  return `https://brilliant.remotelock.com/oauth/authorize?client_id=${process.env.REACT_APP_REMOTE_LOCK_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${organizationId}&response_type=code`;
};

const EXTERNAL_POPUP_WIDTH = 500;
const EXTERNAL_POPUP_HEIGHT = 600;

export const openExternalPopup = (
  organizationId: string | undefined,
  setExternalPopup: React.Dispatch<any>
): void => {
  if (organizationId === undefined) {
    organizationId = "";
  }
  const left = window.screenX + (window.outerWidth - EXTERNAL_POPUP_WIDTH) / 2;
  const top =
    window.screenY + (window.outerHeight - EXTERNAL_POPUP_HEIGHT) / 2.5;
  const popup = window.open(
    getExternalPopupURL(organizationId),
    "RemoteLock",
    `width=${EXTERNAL_POPUP_WIDTH},height=${EXTERNAL_POPUP_HEIGHT},left=${left},top=${top}`
  );
  setExternalPopup(popup);
};

type PropertyType<T extends IPropertyData[] | IRemoteLockHome[]> =
  T extends IPropertyData[] ? IPropertyData[] : IRemoteLockHome[];

/**
 * Returns a sorted list of properties by property name. Sort is
 * case-insensitive.
 * @param properties A list of properties with `propertyName` as an attribute.
 * @returns The list of properties sorted by property name.
 */
export function sortPropertiesByName<
  T extends IPropertyData[] | IRemoteLockHome[]
>(properties: T): PropertyType<T> {
  return [...properties].sort(
    (
      propertyA: IPropertyData | IRemoteLockHome,
      propertyB: IPropertyData | IRemoteLockHome
    ) => propertyA.propertyName.localeCompare(propertyB.propertyName)
  ) as PropertyType<T>;
}

/**
 * Sorts the given list of access devices alphanumerically by name. The sort is
 * case-insensitive.
 * @param devices A list of IRemoteLockIntegrationDevices.
 * @returns The sorted list.
 */
export const sortAccessDevicesByName = (
  devices: IRemoteLockIntegrationDevice[]
): IRemoteLockIntegrationDevice[] =>
  devices.sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));

/*
 * Mock data
 */

export const conflictUnits = [
  "Unit 101",
  "Unit 102",
  "Unit 103",
  "Unit 104",
  "Unit 105",
  "Unit 106",
  "Unit 107",
  "Unit 108",
  "Unit 109",
  "Unit 110",
  "Unit 111",
];

export const organizationId = "01707948c4690009ac79bbf8dceb2b37";

export const buildingsMock: IPropertyData[] = [
  {
    addressLine1: "1234 Sad rd",
    addressLine2: "",
    city: "San Mateo",
    id: "968d482d-f9f9-4e80-8650-92a34e394714",
    propertyName: "Building 101",
    state: "CA",
    zipcode: "94000",
  },
  {
    addressLine1: "1234 Very Sad rd",
    addressLine2: "",
    city: "San Mateo",
    id: "968d482d-f9f9-4e80-8650-92a34e394715",
    propertyName: "Building 102",
    state: "CA",
    zipcode: "94000",
  },
];

export const buildingMock101: IRemoteLockBuilding = {
  homes: [
    {
      id: "home1234",
      propertyName: "Unit 101 With Very Very Long name",
      remotelockDoorGroups: [
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "DoorGroup101",
            name: "Main Entrance 1",
            numDevices: 5,
          },
          id: "DoorGroups101",
        },
      ],
      remotelockUnitAccessDevices: [
        {
          device: {
            accessibleType: LOCK,
            id: "Device101",
            name: "Main Entrance",
          },
          id: "Device101ToProperty101",
        },
        {
          device: {
            accessibleType: LOCK,
            id: "Device102",
            name: "Entrance 2",
          },
          id: "Device102ToProperty101",
        },
        {
          device: {
            accessibleType: LOCK,
            id: "Device103",
            name: "Entrance 3",
          },
          id: "Device103ToProperty101",
        },
        {
          device: {
            accessibleType: LOCK,
            id: "Device104",
            name: "Entrance 4",
          },
          id: "Device104ToProperty101",
        },
        {
          device: {
            accessibleType: LOCK,
            id: "Device105",
            name: "Entrance 5",
          },
          id: "Device105ToProperty101",
        },
      ],
    },
    {
      id: "home123458",
      propertyName: "Unit 102",
      remotelockDoorGroups: [
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "DoorGroup101",
            name: "Main Entrance 1",
            numDevices: 3,
          },
          id: "DoorGroups101",
        },
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "DoorGroup102",
            name: "Main Entrance 2",
            numDevices: 0,
          },
          id: "DoorGroups102",
        },
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "DoorGroup103",
            name: "Main Entrance 3",
            numDevices: 1,
          },
          id: "DoorGroups103",
        },
      ],
      remotelockUnitAccessDevices: [],
    },
  ],
  id: "968d482d-f9f9-4e80-8650-92a34e394714",
};
export const buildingMock102: IRemoteLockBuilding = {
  homes: [
    {
      id: "home1234",
      propertyName: "Unit 101",
      remotelockDoorGroups: [
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "Device101",
            name: "Entrance 1",
          },
          id: "Device101ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "Device102",
            name: "Entrance 2",
          },
          id: "Device102ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "Device104",
            name: "Entrance 4",
          },
          id: "Device104ToProperty101",
        },
      ],
      remotelockUnitAccessDevices: [],
    },
    {
      id: "home123458",
      propertyName: "Unit 102",
      remotelockDoorGroups: [
        {
          doorGroup: {
            accessibleType: REMOTE_LOCK,
            id: "Device101",
            name: "Entrance 1",
          },
          id: "Device101ToProperty102",
        },
      ],
      remotelockUnitAccessDevices: [],
    },
  ],
  id: "968d482d-f9f9-4e80-8650-92a34e394715",
};
export const buildingMock103: IRemoteLockBuilding = {
  homes: [
    {
      id: "home1234",
      propertyName: "Unit 101",
      remotelockDoorGroups: [
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device101",
            name: "Entrance 1",
          },
          id: "Device101ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device102",
            name: "Entrance 2",
          },
          id: "Device102ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device103",
            name: "Entrance 3",
          },
          id: "Device103ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device104",
            name: "Entrance 4",
          },
          id: "Device104ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device105",
            name: "Entrance 5",
          },
          id: "Device105ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device106",
            name: "Pool Lock",
          },
          id: "CommonDevice106ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device107",
            name: "Pool Lock 2",
          },
          id: "CommonDevice107ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device108",
            name: "Pool Lock 3",
          },
          id: "CommonDevice108ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device109",
            name: "Pool Lock 4",
          },
          id: "CommonDevice109ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device111",
            name: "Entrance 6",
          },
          id: "CommonDevice111ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device112",
            name: "Pool Lock 5",
          },
          id: "CommonDevice112ToProperty101",
        },
        {
          doorGroup: {
            accessibleType: LOCK,
            id: "Device113",
            name: "Lock Device With A very  Long Name For testing purposes",
          },
          id: "CommonDevice113ToProperty101",
        },
      ],
      remotelockUnitAccessDevices: [],
    },
  ],
  id: "968d482d-f9f9-4e80-8650-92a34e394716",
};

export const accessDevicesMock: IRemoteLockIntegrationDevice[] = [
  {
    accessibleType: LOCK,
    id: "Device101",
    name: "Main Entrance",
  },
  {
    accessibleType: LOCK,
    id: "Device102",
    name: "Entrance 2",
  },
  {
    accessibleType: LOCK,
    id: "Device103",
    name: "Entrance 3",
  },
  {
    accessibleType: LOCK,
    id: "Device104",
    name: "Entrance 4",
  },
  {
    accessibleType: LOCK,
    id: "Device105",
    name: "Entrance 5",
  },
  {
    accessibleType: LOCK,
    id: "Device106",
    name: "Pool Lock",
  },
  {
    accessibleType: LOCK,
    id: "Device107",
    name: "Pool Lock 2",
  },
  {
    accessibleType: LOCK,
    id: "Device108",
    name: "Pool Lock 3",
  },
  {
    accessibleType: LOCK,
    id: "Device109",
    name: "Pool Lock 4",
  },
  {
    accessibleType: LOCK,
    id: "Device111",
    name: "Entrance 6",
  },
  {
    accessibleType: LOCK,
    id: "Device112",
    name: "Pool Lock 5",
  },
  {
    accessibleType: LOCK,
    id: "Device113",
    name: "Lock Device With A very  Long Name For testing purposes",
  },
];

export const availableDoorGroupsMock: IRemoteLockIntegrationDevice[] = [
  {
    accessibleType: REMOTE_LOCK,
    id: "DoorGroup101",
    name: "Main Entrance 1",
    numDevices: 3,
  },
  {
    accessibleType: REMOTE_LOCK,
    id: "DoorGroup102",
    name: "Main Entrance 2",
    numDevices: 0,
  },
  {
    accessibleType: REMOTE_LOCK,
    id: "DoorGroup103",
    name: "Main Entrance 3",
    numDevices: 1,
  },
];

export const accessDevicesToAddMock: IRemoteLockIntegrationDevice[] = [
  {
    accessibleType: REMOTE_LOCK,
    id: "Device104",
    name: "Entrance 4",
  },
  {
    accessibleType: REMOTE_LOCK,
    id: "Device105",
    name: "Entrance 5",
  },
];

export const accessDevicesToRemoveMock: IRemoteLockIntegrationDevice[] = [
  {
    accessibleType: REMOTE_LOCK,
    id: "Device101",
    name: "Entrance 1",
    remotelockAccessGrantId: "Device101ToProperty102",
  },
  {
    accessibleType: REMOTE_LOCK,
    id: "Device102",
    name: "Entrance 2",
    remotelockAccessGrantId: "Device102ToProperty101",
  },
];

export const AddRemotelockDevicesToPropertiesMock = {
  request: {
    query: ADD_REMOTELOCK_DEVICES_TO_PROPERTIES,
    variables: {
      remotelockDeviceAssignments: [
        {
          deviceType: "UNIT_ACCESS_DEVICE",
          propertyId: buildingMock101.id,
          remotelockAccessibleType: LOCK,
          remotelockDeviceId: "Device101",
        },
      ],
    },
  },
  result: {
    data: {
      count: 0,
    },
  },
};

export const GetRemoteLockBuildingMock = {
  request: {
    query: GET_REMOTE_LOCK_BUILDING,
    variables: { buildingId: buildingMock101.id },
  },
  result: {
    data: {
      building: buildingMock101,
    },
  },
};

export const GetRemoteLockBuildingMock2 = {
  request: {
    query: GET_REMOTE_LOCK_BUILDING,
    variables: { buildingId: buildingMock102.id },
  },
  result: {
    data: {
      building: buildingMock102,
    },
  },
};

export const GetRemoteLockIntegrationForOrganizationMock = {
  request: {
    query: GET_INTEGRATIONS_FOR_ORGANIZATION,
    variables: { organizationId: "" },
  },
  result: {
    data: {
      organization: {
        integrations: [
          {
            __typename: "RemoteLock",
            devices: accessDevicesMock,
            integrationType: REMOTE_LOCK,
          },
        ],
      },
    },
  },
};

export const GetRemoteLockBuildingsIdAndBuildingMock = [
  {
    request: {
      query: GET_ORGANIZATION_BUILDINGS,
      variables: { organizationId: "" },
    },
    result: {
      data: {
        organization: {
          buildings: buildingsMock,
          id: organizationId,
        },
      },
    },
  },
  GetRemoteLockBuildingMock,
  GetRemoteLockBuildingMock2,
  GetRemoteLockIntegrationForOrganizationMock,
];

export const getOrganizationWithNoHomesMock = [
  {
    request: {
      query: GET_ORGANIZATION_BUILDINGS,
      variables: { organizationId: "" },
    },
    result: {
      data: {
        organization: {
          buildings: [],
          id: organizationId,
        },
      },
    },
  },
  GetRemoteLockIntegrationForOrganizationMock,
];

export const mocksWithConfigurations = [
  {
    request: {
      query: GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
      variables: {
        organizationId,
      },
    },
    result: {
      data: {
        organization: {
          integrations: [],
        },
      },
    },
  },
  {
    request: {
      query: GET_CONFIGURATIONS,
    },
    result: {
      data: {
        configurations: [
          { id: "1234", title: "Vacant" },
          { id: "1235", title: "Show Unit" },
        ],
      },
    },
  },
];

export const mocksWithAllIntegrations = [
  {
    request: {
      query: GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
      variables: {
        organizationId,
      },
    },
    result: {
      data: {
        organization: {
          integrations: [
            {
              integrationType: REMOTE_LOCK,
            },
            {
              integrationType: ENTRATA,
            },
            {
              integrationType: REAL_PAGE,
            },
          ],
        },
      },
    },
  },
];

export const mocksWithRemoteLockIntegration = [
  {
    request: {
      query: GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
      variables: {
        organizationId: "",
      },
    },
    result: {
      data: {
        organization: {
          integrations: [
            {
              integrationType: REMOTE_LOCK,
            },
          ],
        },
      },
    },
  },
];

export const mocksWithoutIntegrations = [
  {
    request: {
      query: GET_INTEGRATION_TYPES_FOR_ORGANIZATION,
      variables: {
        organizationId: "",
      },
    },
    result: {
      data: {
        organization: {
          integrations: [],
        },
      },
    },
  },
];
