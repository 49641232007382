import { Link, useParams } from "react-router-dom";

import { IConfigurationData } from "../../interfaces/interfaces";
import { ReactComponent as Settings } from "../../img/settings.svg";

export interface IConfigurationsCard {
  configuration: IConfigurationData;
}

const ConfigurationsCard: React.FC<IConfigurationsCard> = ({
  configuration,
}) => {
  const params = useParams();
  const { organizationId } = params;
  return (
    <Link
      to={`/${organizationId}/configurations/${configuration.id}`}
      className="configuration-card-container"
    >
      <div className="configuration-card-line">
        <div className="unit-title">{configuration.title}</div>
        <Settings title="Settings" className="configuration-card-line-img" />
      </div>
    </Link>
  );
};

export default ConfigurationsCard;
