import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { StatusCodes } from "http-status-codes";
import { AxiosError } from "axios";

import { postInviteManager } from "../../api/organizations";
import { getToken } from "../../utils/auth_token";
import EWebAPIError from "../../api/errors";
import { IAppState } from "../../interfaces/interfaces";
import {
  CLEAR_INVITED_USER_ERROR,
  INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION,
} from "../constants";

export const inviteManager =
  (
    email: string,
    closeModalFn: () => void,
    refetch: () => void,
    organizationId: string
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await postInviteManager(email, organizationId, getToken() || "");
      closeModalFn();
      refetch();
      toast("Invite Sent");
    } catch (error) {
      /* eslint-disable camelcase */
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.CONFLICT &&
        response.data.error_id === EWebAPIError.USER_ALREADY_EXISTS_FOR_PROPERTY
      ) {
        dispatch({ type: INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION });
      } else {
        Sentry.captureMessage(
          `Route "emails_send_admin_portal_invitation" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const clearInvitedUserError = (
  dispatch: Dispatch,
  getState: () => IAppState
): void => {
  if (getState().userManagement.invitedUserError)
    dispatch({ type: CLEAR_INVITED_USER_ERROR });
};
